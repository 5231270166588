import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import RaghavOptionsHomePage from "./components/RaghavOptionsHomePage";
import RaghavOptionsAboutPage from "./components/RaghavOptionsAboutPage";
import RaghavOptionsServicesPage from "./components/RaghavOptionsServicesPage";
import RaghavOptionsServicesClassWebinar from "./components/RaghavOptionsServicesClassWebinar";
import RaghavOptionOurInfo from "./components/RaghavOptionOurInfo";
import RaghavOptionsContactUs from "./components/RaghavOptionsContactUs";
import SomeArticles from "./components/SomeArticles";

function App() {
  return (
    <div className="App font">
      <Header></Header>
      <Routes>
        <Route
          path="/"
          element={<RaghavOptionsHomePage></RaghavOptionsHomePage>}
        ></Route>
        <Route
          path="/About"
          element={<RaghavOptionsAboutPage></RaghavOptionsAboutPage>}
        ></Route>
          <Route
          path="/Services"
          element={<RaghavOptionsServicesPage></RaghavOptionsServicesPage>}
        ></Route>
        <Route
          path="/ClassesWebinar"
          element={<RaghavOptionsServicesClassWebinar></RaghavOptionsServicesClassWebinar>}
        ></Route>
        <Route
          path="/OurInfo"
          element={<RaghavOptionOurInfo></RaghavOptionOurInfo>}
        ></Route>
         <Route
          path="/ContactUs"
          element={<RaghavOptionsContactUs></RaghavOptionsContactUs>}
        ></Route>
        <Route
          path="/SomeArticles"
          element={<SomeArticles></SomeArticles>}
        ></Route>
       
      </Routes>
      
      
      <Footer></Footer>
    </div>
  );
}

export default App;
