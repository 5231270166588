import "bootstrap/dist/css/bootstrap.min.css";
import "./Header.css";
import React, { useState } from "react";
import RaghavOptionsAboutPage from "./RaghavOptionsAboutPage";
import RaghavOptionsHomePage from "./RaghavOptionsHomePage";
import RaghavOptionsServicesPage from "./RaghavOptionsServicesPage";
import RaghavOptionOurInfo from "./RaghavOptionOurInfo";
import logo from "./logo.png";
import { Link } from "react-router-dom";

import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaYoutubeSquare,
} from "react-icons/fa";
import Hamburger from 'hamburger-react';

const Header = () => {
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  const [isOpen, setOpen] = useState(false)
  const [activeItem, setActiveItem] = useState('');
  return (
    <div class="headerPart">
      <nav className="main-nav container">
        {/* 1st logo part  */}
        <div className="logo ">
          <Link to="/">
          <img src={logo} alt="Logo" >

            </img>
            </Link>
        </div>

        {/* 2nd menu part  */}
        <div
          className={
            showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
          }
        >
          <ul>
            <li>
            <Link to="/About">About</Link>
            </li>
            <li>
              <Link to="/Services">Services</Link>
            </li>
            <li>
              <Link to="/ClassesWebinar">Class & Webinar</Link>
            </li>
            <li>
              <Link to="/OurInfo">Our Info</Link>
            </li>
            <li>
              <Link to="/ContactUs">Contact Us</Link>
            </li>
            
            
            
          </ul>
        </div>

        {/* 3rd social media links */}
        <div className="social-media">
          <ul className="social-media-desktop">
            <li>
              <a
                href=" https://www.facebook.com/Raghavoptions?mibextid=ZbWKwL"
                target=""
              >
                <FaFacebookSquare className="facebook" />
              </a>
            </li>
            
            <li>
              <a
                href="https://instagram.com/raghavoptions?igshid=YmMyMTA2M2Y="
                target=""
              >
                <FaInstagramSquare className="instagram" />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/@raghavoptions7389"
                target=""
              >
                <FaYoutubeSquare className="youtube" />
              </a>
            </li>
          </ul>

          {/* hamburger menu start  */}
          <div className="hamburger-menu">
            <a href="#" onClick={() => setShowMediaIcons(!showMediaIcons)}>
            
            <Hamburger toggled = {isOpen} toggle={setOpen} />
            </a>
          </div>
        </div>
      </nav>
    
    </div>
  );
};

export default Header;
