import "./RaghavOptionsHomePage.css";
import video from "./home.mp4";
import faq from "./faq.png"
import ReactPlayer from "react-player";
import WhyChooseUsImg from "./WhyChooseUs.png";
import highestLevel from "./higestlevel.png";
import IndividuaAttentionClients from "./IndividuaAttentionClients.png";
import AbsoluteClientsSatisfaction from "./AbsoluteClientsSatisfaction.png";
import FairInvestmentServices from "./FairInvestmentServices.png";
import Trishul from "./Trishul.png";
import Vajra from "./Vajra.png";
import Narayan from "./Narayan.png";
import Chankya from "./Chankya.png";
import ResultsDriven from "./ResultsDriven.png";
import Dhanush from "./Dhanush.png";
import sachin from "./2.png";
import rushikesh from "./3.png";
import raghavendra from "./1.png";
import cafeco2 from "./Rectangle 514.png";
import weik from "./Rectangle 515.png";
import eaquir from "./Rectangle 516.png";
import mllen from "./Rectangle 517.png";
import spray from "./Rectangle 521.png";
import khyatee from "./Rectangle 522.png";
import rmc from "./Rectangle 523.png";
// import {Helmet} from "react-helmet"
import React, { useEffect, useState } from "react";
import Accordion from "./accordion";
import { accordionData } from "./contents/content";
import List from "./popupForm";

function RaghavOptionsHomePage() {
  // const myStyle = {
  //   backgroundImage:'url(${WhyChooseUsImg})',
  //   height:'100%',
  //   width:'100%',
  //   padding:'20px',
  //   backgroundSize:'cover',
  //   backgroundReaeat:'no-repeat',
  // };

  return (
   

    <div>
     <List></List>
      {/* marquee part */}
      {/* <div class="row">
        <div class="col-12">
          <div>
            <div class="marquee">
              <ul class="marquee__content">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
              </ul>
              <ul aria-hidden="true" class="marquee__content">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
              </ul>
            </div>
          </div>
        </div>
      </div> */}

     
      <div>
        <section class="outter hero-video">
          <section class="video-container">
            <video className="videoTag" autoPlay loop muted>
              <source src={video} type="video/mp4" />
            </video>
            {/* <div class="callout container">
              <h1>HIRE AN EXPERT</h1>

              <h4>FOR DAILY TRADES</h4>
            </div> */}
          </section>
        </section>
      </div>

      {/* Why choose us  */}
      <div class="whyChooseUs container">
        <h1 class="mainTitle">Why choose us ?</h1>
        <div class="row">
          <div class="col-md-12 col-lg-6 col-xl-6 col-sm-12 whyChoosePara">
            <p>
              <span style={{ color: "#19024B", fontWeight:'bold' }}>Raghav Option's</span> Advisory
              Services is a team of professionals. It’s a Advisory platform
              where group of professionals which include Senior Research Analyst
              & Relationship Managers who are working as a team for the service
              of there clients. The aim of the team is to filter the best
              potential trading opportunities in the market following certain in
              build back-tested guidelines. The inputs filtered are shared with
              the team and passed-on to the clients with strict stop-losses and
              targets. The aim of the research and risk-profiling of the clients
              is to provide them the best possible trading
              opportunities.Consistency of accuracy in inputs and strict
              risk-management for the benefit of the clients is the sole aim of
              the team as a whole.
            </p>
            <p>
              <span style={{ color: "#19024B", fontWeight:'bold'  }}>Raghav Option's</span> Advisory
              Services platform provided tradable research inputs in Equity,
              Commodity & Currency segment of stock market on all different
              time-frames (Intraday/ Delivery/ Positional & Over-Night trades).
              Fulfilling complete guidelines & regulations of SEBI IA
              Regulations. Raghav Option's team keeps a complete touch base and
              attempts to provide a customize research services to clients as
              per there risk-appetite and goals. High Professionalism,
              Transparency, Consistency & Optimum client satisfaction is the
              goal & vision of the team.
            </p>
          </div>
          {/* <div class="col-xs-1 col-1 col-sm-1 col-md-1 col-xl-1"></div> */}
          {/* <div class="col-6  WhyChooseUsImg">
            <img src={WhyChooseUsImg} alt="WhyChooseUsImg" />
          </div> */}
          <div class="col-6" style={{backgroundImage:'url('+WhyChooseUsImg+')', borderRadius:'30px', backgroundSize:'cover'}}>

          </div>
        </div>
      </div>

      {/* Our specialty */}
      <div class="ourSpecialty">
        <h1 class="mainTitle"> Our specialty</h1>
        <section class="services ourSpecialtyMain" id="services">
          <div class="container">
            <div class="row">
            
              <div class="col-md-12 col-xl-4 col-lg-4 col-sm-12">
                <div class="box">
                  <div>
                    <div className="servicesIcon ">
                      <img src={highestLevel} alt="highestLevel" />
                    </div>

                    <h3 class="title">
                      <a href="">Highest Level Accuracy</a>
                    </h3>
                    <p class="description">
                      Among the best research inputs in the industry, our
                      research inputs are 80-90% accurate.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-xl-4 col-lg-4 col-sm-12 ">
                <div class="box">
                  <div>
                    <div className="servicesIcon ">
                      <img src={ResultsDriven} alt="ResultsDriven" />
                    </div>

                    <h3 class="title">
                      <a href="">Results Driven</a>
                    </h3>
                    <p class="description">
                      Investment Advisory strives to provide our clients with
                      the highest level of satisfaction through the provision of
                      the best possible inputs & results.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-xl-4 col-lg-4 col-sm-12">
                <div class="box">
                  <div>
                    <div className="servicesIcon ">
                      <img
                        src={IndividuaAttentionClients}
                        alt="IndividuaAttentionClients"
                      />
                    </div>

                    <h3 class="title">
                      <a href="">Individual Attention To all Clients</a>
                    </h3>
                    <p class="description">
                      One-to-one one-to-one services are provided to clients by
                      Raghav Options Investment Advisory RM. In accordance with
                      their risk-profiling and base capital requirements.
                    </p>
                  </div>
                </div>
              </div>
         
            </div>
            <div class="row d-flex justify-content-center">
              
              <div class="col-md-12 col-xl-4 col-lg-4 col-sm-12">
                <div class="box">
                  <div>
                    <div className="servicesIcon ">
                      <img
                        src={AbsoluteClientsSatisfaction}
                        alt="AbsoluteClientsSatisfaction"
                      />
                    </div>

                    <h3 class="title">
                      <a href="">Absolute Clients Satisfaction</a>
                    </h3>
                    <p class="description">
                      Past and present clients have generally expressed
                      satisfaction and positive feedback about our services.
                      Every effort is made to make sure they are taken care of
                      as best as possible.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-12 col-xl-4 col-lg-4 col-sm-12">
                <div class="box">
                  <div>
                    <div className="servicesIcon ">
                      <img
                        src={FairInvestmentServices}
                        alt="FairInvestmentServices"
                      />
                    </div>

                    <h3 class="title">
                      <a href="">Fair Investment Services</a>
                    </h3>
                    <p class="description">
                      According to SEBI regulations, we work in accordance with
                      their structured guidelines. All our clients benefit from
                      this transparency and fairness.
                    </p>
                  </div>
                </div>
              </div>
            
            </div>
          </div>
        </section>
      </div>

      {/* Option Strategies */}

      <div>
        <div class="optionStrategies container">
          <h1 class="mainTitle"> Option Strategies</h1>
          <div class="feature">
            <div class="item item--1  box">
              <div className="optionIcon ">
                <img src={Trishul} alt="Trishul" />
              </div>
              <h3 class="title">
                <a href="">Trishul</a>
              </h3>
            </div>
            <div class="item item--2  box">
              <div className="optionIcon ">
                <img src={Narayan} alt="Narayan" />
              </div>
              <h3 class="title">
                <a href="">Narayan</a>
              </h3>
            </div>
            <div class="item item--3  box">
              <div className="optionIcon">
                <img src={Vajra} alt="Vajra" />
              </div>
              <h3 class="title">
                <a href="">Vajra</a>
              </h3>
            </div>
            <div class="item item--4  box">
              <div className="optionIcon ">
                <img src={Chankya} alt="Chankya" />
              </div>
              <h3 class="title">
                <a href="">Chankya</a>
              </h3>
            </div>
            <div class="item box">
              <div className="optionIcon">
                <img src={Dhanush} alt="Dhanush" />
              </div>
              <h3 class="title">
                <a href="">Dhanush</a>
              </h3>
            </div>
          </div>
        </div>
      </div>

      {/* Our Team */}

      <div class="ourTeam">
        <h1 class="mainTitle">Our Team</h1>
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-lg-3 col-sm-12">
              <div class="our-team">
                <div class="team_img">
                  <img src={raghavendra} alt="raghavendra" />
                </div>
              </div>
            </div>

            <div class="col-md-6 col-lg-3 col-sm-12">
              <div class="our-team">
                <div class="team_img">
                  <img src={rushikesh} alt="rushikesh" />
                </div>
              </div>
            </div>

            <div class="col-md-6 col-lg-3 col-sm-12">
              <div class="our-team">
                <div class="team_img">
                  <img src={sachin} alt="sachin" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Take a look at Our Clients */}
      <div class="ourClients container">
        <h1 class="mainTitle">Take a look at Our Clients</h1>

        <div>
          <div class="row">
            <div class="col-xl-3 col-lg-4 col-md-6 col-xs-12 clientss">
              <div class="clients">
            
                  <img src={cafeco2} alt="cafeco2" />
                
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-xs-12 clientss">
              <div class="clients">
           
                  <img src={weik} alt="weik" />
               
              </div>
            </div>
            <div class=" col-xl-3 col-lg-4 col-md-6 col-xs-12 clientss marginRequired">
              <div class="clients">
             
                  <img src={eaquir} alt="eaquir" />
                
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-xs-12 clientss mllen marginRequired">
              <div class="clients">
             
                  <img src={mllen} alt="mllen" />
            
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="row">
       
            <div class="col-xl-3 col-lg-4 col-md-6 col-xs-12 clientss">
              <div class="clients">
              <img src={spray} alt="spray" />
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-xs-12 clientss">
              <div class="clients">
              <img src={khyatee} alt="khyatee" />
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-xs-12 clientss marginRequired">
              <div class="clients">
              <img src={rmc} alt="rmc" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FAQ  */}
      <div class="faqSection">
      <h1 class="mainTitle mainTitle1">
          FAQ
        </h1>
      <div className="accordion container">
       
        <div class="row">
          <div class="col-md-12 col-lg-6 col-sm-12 ">
          {accordionData.map(({ title, content }) => (
          <Accordion title={title} content={content} />
        ))}
          </div>
          <div class="col-2"></div>
          <div class="col-md-4 col-lg-4 col-sm-12" style={{backgroundImage:'url('+faq+')',    backgroundSize: 'contain'
,    backgroundRepeat: 'no-repeat'}}>
            
            </div>
        </div>
      
      </div>
      </div>

    </div>
  );
}

export default RaghavOptionsHomePage;
