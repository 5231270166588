import React from "react";
import aboutVideo from "./about.mp4";
import "./RaghavOptionsAboutPage.css";
import vision from "./vision.png";
import mission from "./mission.png";
import value from "./value.png";
import WhyChooseUsImg from "./OurStory.png";
import aboutImg from "./Ellipse 8.png";
function RaghavOptionsAboutPage() {
  return (
    <>
      {/* banner part  */}
      <div class="AboutPageBanner font">
        <section class="bannerImg banner-video">
          <section class="video-container">
            <video className="videoTag" autoPlay loop muted>
              <source src={aboutVideo} type="video/mp4" />
            </video>
            <div class="BannerText container">
              <h1 style={{color:'black', fontWeight:'bold'}}>About Us</h1>
            </div>
          </section>
        </section>
      </div>

      {/* about value vision mission */}
      <div class="container">
        <div class="row aboutFlip ">
          {/* <h1 class="mainTitle">Goal, Value & Mission</h1> */}
          <div class="three_columns">
            <figure class="img_flip img_flip-ver">
              <img src={vision} alt="vision" />
              <p class="aboutCenterContent" style={{fontWeight:'bold'}}>Our Goal</p>
              <figcaption>
                <h1 class="visionTitle" style={{fontWeight:'bold'}}>Our Goal</h1>
                <p class="aboutPara">
                  Our goal is to help our clients achieve financial independence
                  and peace of mind. We are passionate about what we do, and we
                  are dedicated to providing our clients with the tools and
                  guidance they need to achieve their financial goals.
                </p>
              </figcaption>
            </figure>
          </div>
          <div class="three_columns">
            <figure class="img_flip img_flip-ver">
              <img src={mission} alt="mission" />
              <p class="aboutCenterContent" style={{fontWeight:'bold'}}>Our Mission</p>
              <figcaption>
                <h1 style={{fontWeight:'bold'}}>Our Mission</h1>
                <p class="aboutPara">
                  Our mission is to empower our clients with the knowledge and
                  tools they need to make informed investment decisions in the
                  share market. We believe that by providing timely and accurate
                  information, expert analysis, and personalized advice, we can
                  help our clients achieve their financial goals and build
                  long-term wealth.
                </p>
              </figcaption>
            </figure>
          </div>
          <div class="three_columns">
            <figure class="img_flip img_flip-ver">
              <img src={value} alt="value" />
              <p class="aboutCenterContent" style={{fontWeight:'bold'}}>Our Value</p>
              <figcaption>
                <h1 style={{fontWeight:'bold'}}>Our Vision</h1>

                <p class="aboutPara">
                  At the heart of our value proposition is a commitment to
                  transparency and accountability. We believe in educating our
                  clients on the investment process and keeping them informed
                  every step of the way. We take pride in our ability to develop
                  long-term relationships with our clients and to be their
                  trusted partner throughout their investment journey.
                </p>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
      {/* our story */}
      <div class="ourStory ">
        <h1 class="mainTitle">Our Story</h1>
        <div class="ourStorySection container">
          <div class="row ourStoryParagharph">
            <div class="col-md-12  col-xl-6 col-lg-6 col-sm-12 ourStoryPara">
              <p>
                Raghvendra Singh is a professional trader and investor with an
                edge for Index Derivatives’. He started trading in the Indian
                stock market in 2005 and is the founder of Phoenix Investments.
                He is also instrumental in consulting and working closely with
                the strategy and functioning of Arthashastra Wealth advisory pvt
                ltd and clover security pvt ltd. He is currently handling the
                role of Chief Derivatives Strategist managing a portfolio of 50
                cr. His role includes the following
              </p>
              <p>
                Our strategies have been consistently delivering return between
                1% and 1.5% per week and have outperformed the benchmark
                consistently since 2017. Because of the strong roots and
                inspiration from the ancient scriptures, Our strategies have
                been named based on Mahabharata. The concept of bulls and bears
                are linked to Pandavas and Kauravas.
              </p>
            </div>
            <div class="col-md-12  col-xl-6 col-lg-6 col-sm-12 ourStorySecond">
              <ul>
                <li style={{ fontWeight: "bold" }}>
                  <p style={{ fontSize: "16px" }}>
                    {" "}
                    <img
                      src={aboutImg}
                      alt="aboutImage"
                      style={{
                        height: "30px",
                        weight: "30px",
                        paddingRight: "1.5rem",
                      }}
                    />
                    Set objective and goals based on market direction.
                  </p>
                </li>
                <li style={{ fontWeight: "bold" }}>
                  <p style={{ fontSize: "16px" }}>
                    {" "}
                    <img
                      src={aboutImg}
                      alt="aboutImage"
                      style={{
                        height: "30px",
                        weight: "30px",
                        paddingRight: "1.5rem",
                      }}
                    />
                    Generate ideas/strategies with paper entry and exit exit
                    plans.
                  </p>
                </li>
                <li style={{ fontWeight: "bold" }}>
                  <p style={{ fontSize: "16px" }}>
                    {" "}
                    <img
                      src={aboutImg}
                      alt="aboutImage"
                      style={{
                        height: "30px",
                        weight: "30px",
                        paddingRight: "1.5rem",
                      }}
                    />
                    Execution of ideas and allocation of capital based on the
                    risk /tolerance  profile of client.
                  </p>
                </li>
                <li style={{ fontWeight: "bold" }}>
                  <p style={{ fontSize: "16px" }}>
                    {" "}
                    <img
                      src={aboutImg}
                      alt="aboutImage"
                      style={{
                        height: "30px",
                        weight: "30px",
                        paddingRight: "1.5rem",
                      }}
                    />
                    Timely reviews of strategies and adjustment positions when
                    needed.
                  </p>
                </li>
                <li style={{ fontWeight: "bold" }}>
                  <p style={{ fontSize: "16px" }}>
                    {" "}
                    <img
                      src={aboutImg}
                      alt="aboutImage"
                      style={{
                        height: "30px",
                        weight: "30px",
                        paddingRight: "1.5rem",
                      }}
                    />
                    Exiting positions when the desired economic objective is
                    fulfilled.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="ourStoryBottom container">
        <div class="row">
          <div class="col-md-12  col-xl-6 col-lg-6 col-sm-12" style={{backgroundImage:'url('+WhyChooseUsImg+')', borderRadius:'30px', backgroundSize:'cover'}}>

          </div>
          <div class="col-md-12  col-xl-6 col-lg-6 col-sm-12 ourStoryBottomSecond" >
            <p style={{fontWeight:'bold', color:'#19024B'}} class="strategies">
            The strategies are named after the weapons used in Mahabharat
            </p>
            <ul style={{listStyleType:'upper-roman', display:'visible', fontSize:'16px'}}>
              <li><p>Trishul strategy: This is ever green strategy . We take this strategy frequently on weekly basis once VIX is above 17. </p></li>
              <li><p>Narayan: This strategy is ment for range bound market . Ideal Vix for this is above 20. 	</p></li>
              <li><p>Vajra: Ideal tieming fro this strategy is when Vix is above 15. </p></li>
              <li><p>Triveni: We take this strategy when VIX is blow 15 with proper hedge. </p></li>
              <li><p>Pandav: Low Vix and and Range bound mkt.</p></li>
              <li><p>Dhanush: Month strategy with Vix below 15 ( more or less its directional trade )</p></li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default RaghavOptionsAboutPage;
