import React, { useState } from "react";
import "./contactForm.css";
import styled from "styled-components";
const Contact = () => {
  const Wrapper = styled.section`
    padding: 9rem 0 5rem 0;
    .container {
      margin-top: 6rem;
      text-align: center;
      .contact-form {
        max-width: 50rem;
        margin: auto;
        .contact-inputs {
          display: flex;
          flex-direction: column;
          gap: 3rem;
          input[type="submit"] {
            cursor: pointer;
            transition: all 0.2s;
            &:hover {
              background-color: ${({ theme }) => theme.colors.white};
              border: 1px solid ${({ theme }) => theme.colors.btn};
              color: ${({ theme }) => theme.colors.btn};
              transform: scale(0.9);
            }
          }
        }
      }
    }
  `;

  return (
 <div id="contact-form">
     <form   action="https://formspree.io/f/myyaowzn"
            method="POST"
            className="contact-inputs">
       <input type="text" required name="username"  autoComplete="off" placeholder="User Name"  class="contactFormm" />
        <hr></hr>

       <br></br>
     <input type="email"  autoComplete="off" placeholder="Your email address" name="Email" class="contactFormm "  required/>   <hr></hr>
        <br></br>
       <textarea placeholder="Your message"  autoComplete="off"   name="message"  required class="contactFormm " ></textarea>   <hr></hr>
        <br></br>
        <button type="submit" class="" style={{width:'100%', color:'white', background:'#19024B',  border:'none', padding:'10px', fontSize:'14px'}} value="send">Send Message</button>
        <br></br>
       <br></br>
        <span style={{fontSize:'14px'}}>Thank you for your message, we will be in touch in no time!</span>
 </form>


      {/* <div className="container">
        <div className="contact-form">
          <form
            action="https://formspree.io/f/xgedgjkr"
            method="POST"
            className="contact-inputs"
          >
            <input
              type="text"
              name="username"
              placeholder="username"
              autoComplete="off"
              required
            />

            <input
              type="email"
              name="Email"
              placeholder="Email"
              autoComplete="off"
              required
            />

            <textarea
              name="message"
              cols="30"
              rows="6"
              autoComplete="off"
              required
            ></textarea>

            <input type="submit" value="send" />
          </form>
        </div> */}
      </div>

   
  );
};

export default Contact;
