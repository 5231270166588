import React, { useState } from 'react'

import "/home/user/Desktop/raghavoptions/src/components/popupForm.css"

const Popup = (props) => {
    const close = () => {
        props.onClose();
    };

    const { head, body } = props;

    return (
        <div className="popup">
            <div className="black-section" onClick={close}></div>
            <div className="content-section-wrapper">
                <a href="javascript:;" className="close-button aligner adaptLink" onClick={close}>X</a>
                <div className="content-section">
                    <div className="head-block">
                        {head}
                    </div>
                    <div className="body-block">
                        <div className="body-block-inner">
                            {body}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const List = () => {
    const [visiblePopup, setVisiblePopup] = useState(true);

    const openPopup = () => {
        setVisiblePopup(true);
    };

    const closePopup = () => {
        setVisiblePopup(false);
    };

    let popupTitle = <h2>Disclaimer</h2>;
    let popupBody = <p> Please note that the information provided on this website is for general informational
    purposes only and should not be considered as personalized financial advice. This website is
    operated by a non-SEBI registered financial advisor, and therefore, the information presented here
    may not adhere to the regulations and guidelines set forth by the Securities and Exchange Board of
    India (SEBI).<br></br><br></br>
     The non-SEBI registered financial advisor who operates this website does not guarantee the
    accuracy, completeness, or reliability of the information presented. While efforts are made to ensure
    that the information provided is up to date and accurate, it may be subject to errors or omissions.<br></br><br></br>
     Any investment or financial decision you make based on the information provided on this
    website is at your own risk. The non-SEBI registered financial advisor, its affiliates, partners, or
    employees will not be held liable for any losses, damages, or expenses incurred as a result of relying
    on the information provided. <br></br><br></br></p>;

    return (
        <div>
            <p style={{ textAlign: 'center', display:'none' }}>
                <a href="javascript:;" onClick={openPopup} style={{ textDecoration: 'none', color: '#000', padding: '8px' }} ></a>
            </p>
            {visiblePopup ? <Popup head={popupTitle} body={popupBody} onClose={closePopup} /> : ""}
        </div>
    );
};

export default List;
