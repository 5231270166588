import React from "react";
import "./SomeArticles.css";
import someArticles from "./someArticles.png";
import nifty from "./nifty.png";
import articleVideo from "./7.mp4";
import Rectangle1 from "./Rectangle 421.png";
import Rectangle2 from "./Rectangle 422.png";
import Rectangle3 from "./Rectangle 423.png";
import Rectangle4 from "./Rectangle 424.png";
import Rectangle5 from "./Rectangle5.png";

function SomeArticles() {
  const handleClickScroll = () => {
    const element = document.getElementById("section-1");

    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickScroll1 = () => {
    const element1 = document.getElementById("section-2");

    if (element1) {
      // 👇 Will scroll smoothly to the top of the next section
      element1.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickScroll2 = () => {
    const element2 = document.getElementById("section-3");

    if (element2) {
      // 👇 Will scroll smoothly to the top of the next section
      element2.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickScroll3 = () => {
    const element3 = document.getElementById("section-4");

    if (element3) {
      // 👇 Will scroll smoothly to the top of the next section
      element3.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickScroll4 = () => {
    const element4 = document.getElementById("section-5");

    if (element4) {
      // 👇 Will scroll smoothly to the top of the next section
      element4.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClickScroll5 = () => {
    const element5 = document.getElementById("section-6");

    if (element5) {
      // 👇 Will scroll smoothly to the top of the next section
      element5.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClickScroll6 = () => {
    const element6 = document.getElementById("section-7");

    if (element6) {
      // 👇 Will scroll smoothly to the top of the next section
      element6.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClickScroll7 = () => {
    const element7 = document.getElementById("section-8");

    if (element7) {
      // 👇 Will scroll smoothly to the top of the next section
      element7.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickScroll8 = () => {
    const element8 = document.getElementById("section-9");

    if (element8) {
      // 👇 Will scroll smoothly to the top of the next section
      element8.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickScroll9 = () => {
    const element9 = document.getElementById("section-10");

    if (element9) {
      // 👇 Will scroll smoothly to the top of the next section
      element9.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickScroll10 = () => {
    const element10 = document.getElementById("section-11");

    if (element10) {
      // 👇 Will scroll smoothly to the top of the next section
      element10.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickScroll11 = () => {
    const element11 = document.getElementById("section-12");

    if (element11) {
      // 👇 Will scroll smoothly to the top of the next section
      element11.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickScroll12 = () => {
    const element12 = document.getElementById("section-13");

    if (element12) {
      // 👇 Will scroll smoothly to the top of the next section
      element12.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickScroll13 = () => {
    const element13 = document.getElementById("section-14");

    if (element13) {
      // 👇 Will scroll smoothly to the top of the next section
      element13.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickScroll14 = () => {
    const element14 = document.getElementById("section-15");

    if (element14) {
      // 👇 Will scroll smoothly to the top of the next section
      element14.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickScroll15 = () => {
    const element15 = document.getElementById("section-16");

    if (element15) {
      // 👇 Will scroll smoothly to the top of the next section
      element15.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickScroll16 = () => {
    const element16 = document.getElementById("section-17");

    if (element16) {
      // 👇 Will scroll smoothly to the top of the next section
      element16.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickScroll17 = () => {
    const element17 = document.getElementById("section-18");

    if (element17) {
      // 👇 Will scroll smoothly to the top of the next section
      element17.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickScroll18 = () => {
    const element18 = document.getElementById("section-19");

    if (element18) {
      // 👇 Will scroll smoothly to the top of the next section
      element18.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickScroll19 = () => {
    const element19 = document.getElementById("section-20");

    if (element19) {
      // 👇 Will scroll smoothly to the top of the next section
      element19.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickScroll20 = () => {
    const element20 = document.getElementById("section-21");

    if (element20) {
      // 👇 Will scroll smoothly to the top of the next section
      element20.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickScroll21 = () => {
    const element21 = document.getElementById("section-22");

    if (element21) {
      // 👇 Will scroll smoothly to the top of the next section
      element21.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickScroll22 = () => {
    const element22 = document.getElementById("section-23");

    if (element22) {
      // 👇 Will scroll smoothly to the top of the next section
      element22.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickScroll23 = () => {
    const element23 = document.getElementById("section-24");

    if (element23) {
      // 👇 Will scroll smoothly to the top of the next section
      element23.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickScroll24 = () => {
    const element24 = document.getElementById("section-25");

    if (element24) {
      // 👇 Will scroll smoothly to the top of the next section
      element24.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClickScroll25 = () => {
    const element25 = document.getElementById("section-26");

    if (element25) {
      // 👇 Will scroll smoothly to the top of the next section
      element25.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickScroll26 = () => {
    const element26 = document.getElementById("section-27");

    if (element26) {
      // 👇 Will scroll smoothly to the top of the next section
      element26.scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleClickScroll27 = () => {
    const element27 = document.getElementById("section-28");

    if (element27) {
      // 👇 Will scroll smoothly to the top of the next section
      element27.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div class=" font">
      <div class="articleBanner">
        <section class="serviceBannerImg banner-video">
          <section class="video-container">
            <video className="videoTag" autoPlay loop muted>
              <source src={articleVideo} type="video/mp4" />
            </video>
            <div class="BannerText container">
              <h1 style={{ color: "black" }}>ARTICLES</h1>
            </div>
          </section>
        </section>
      </div>
      <div class="someArticles container">
     
        {/* points */}
        <div class="firstArticlesSectio" style={{backgroundColor:'#f2f2f2'}}>
        <h1 style={{fontSize:'40px', color:'#19024B', fontWeight:'bold',padding:'5rem 5rem'}}>Some Articles</h1>
        <div class="articles someArticlesPoints">
          <div class="someArticless">
            <img src={Rectangle2} alt="Rectangle2" />
          </div>
          <div onClick={handleClickScroll} >
          <p> Top Reasons Invest In Stocks Vs Buying A House</p>
          </div>
        </div><br></br>
        <div class="articles someArticlesPoints">
          <div class="someArticless">
            <img src={Rectangle3} alt="Rectangle3" />
          </div>
          <div onClick={handleClickScroll1}>
          <p>Should You Invest In Auto Stocks In 2023?</p>
          </div>
        </div><br></br>
        <div class="articles someArticlesPoints">
          <div class="someArticless">
            <img src={Rectangle1} alt="Rectangle1" />
          </div>
          <div onClick={handleClickScroll2}>
          <p>How to Invest in Share Market</p>
          </div>
        </div><br></br>
        <div class="articles someArticlesPoints">
          <div class="someArticless">
            <img src={Rectangle4} alt="Rectangle4" />
          </div>
          <div onClick={handleClickScroll3}>
         <p> Shocking But True: 90% of People Lose Money In Stocks</p>
          </div>
        </div><br></br>
        <div class="articles someArticlesPoints">
          <div class="someArticless">
            <img src={Rectangle5} alt="Rectangle5" />
          </div>
          <div onClick={handleClickScroll4}>
       <p>   What Are Alternative Investment Funds- A Complete Guide</p>
          </div>
        </div>
        <br></br><br></br><br></br><br></br><br></br>
        </div>
       <br></br>
        
        
        <h1 class="topicsOurInfo" style={{margin:'0 2rem'}}>
          <span id="section-1"> 1.</span>Top Reasons Invest In Stocks Vs Buying
          A House
        </h1>
        <br></br> <br></br> <br></br>
        <p  style={{margin:'0 2rem'}}> 
          There are two schools of thought in the market regarding deploying
          surplus corpus over the long term- investing in stocks or buying a
          house. Both options have a significant return potential over time, but
          investment economics differs significantly. Therefore, be informed
          before jumping to any conclusion, and understand the effectiveness of
          both investment options.
          <br></br>
          So, should you invest in stocks or real estate? First, let’s compare
          to know the better investment option for the long term -stocks or real
          estate.
        </p>
        <br></br>
        <br></br>
        <ul
          style={{
            backgroundColor: "#f2f2f2",
            padding: "10px 10px",
            fontSize: "18px",
          }}
        >
          <li style={{ listStyle: "none" }}>
            <p onClick={handleClickScroll5}  style={{margin:'0 2rem'}} class="curserPointer">Overview: Stocks vs. Real Estate</p>
            <ul  style={{margin:'0 2rem'}}> 
              <li onClick={handleClickScroll6} class="curserPointer">Ease of Investment</li>
              <li onClick={handleClickScroll7} class="curserPointer">Recurring Costs</li>
            </ul>
          </li>
          <br></br>
          <li style={{ listStyle: "none", margin:'0 2rem' }}  >
            <p onClick={handleClickScroll8} class="curserPointer">
              Returns on Investment: Stocks vs. Real Estate
            </p>
            <ul>
              <li onClick={handleClickScroll9}  class="curserPointer">Returns in equity stocks</li>
              <li onClick={handleClickScroll10}  class="curserPointer">Returns in real estate</li>
              <li onClick={handleClickScroll11}  class="curserPointer">
                Cost of Buying a House On A Home Loan
              </li>
              <li onClick={handleClickScroll12}  class="curserPointer"> 
                Conclusion: Should You Invest In Stocks or Buy A House
              </li>
            </ul>
          </li>
          <br></br>
          <li style={{ listStyle: "none", margin:'0 2rem' }}>
            <p onClick={handleClickScroll13}  class="curserPointer">FAQs</p>
            <ul>
              <li onClick={handleClickScroll14}  class="curserPointer">
                Should I invest in stocks or real estate?
              </li>
              <li onClick={handleClickScroll15}  class="curserPointer">
                What are the disadvantages of investing in real estate?
              </li>
              <li onClick={handleClickScroll16}  class="curserPointer">
                How do you invest in stocks?
              </li>
            </ul>
          </li>
        </ul>
        <br></br>
        <br></br>
        <h2 style={{ fontWeight: "bold", margin:'0 2rem' }} id="section-6">
          Overview: Stocks vs. Real Estate
        </h2>
        <p style={{margin:'0 2rem'}}>
          Investing in stocks and real estate is an investor’s choice, and it
          should depend on the long-term financial goals, risk appetite,
          responsibilities, and financial situation.
          <br></br>
          <br></br>
          Let’s look at the primary differences between investing in stocks and
          real estate.
          <br></br>
          <br></br>
          <span style={{ fontWeight: "bold" }} id="section-7">
            Ease of Investment
          </span>
          <br></br>
          <br></br>
          All you need is a trading account and a Demat account to invest in
          stocks. You can open these accounts with any stock broker at minimum
          charges. And, with some basic research about companies and sectors,
          you can begin to invest in stocks.<br></br>
          However, it needs hard work to start investing in real estate. You
          must identify potential properties, physically evaluate a property,
          negotiate on the prices, finalize the property documents, seek legal
          advice, etc., to take full possession of the property and become an
          owner.<br></br>
          Please note- It’s easy to begin investing in the stock market, but
          finding multibagger stocks can be time-consuming and tricky. That’s
          why you have financial advisors like us. Fill up the form below to get
          a multibagger portfolio that suits you.<br></br>
          <br></br>
          <span style={{ fontWeight: "bold" }} id="section-8">
            Recurring Costs
          </span>
          <br></br>
          <br></br>
          There are small recurring costs associated with holding shares in your
          Demat account or investing in stocks, such as account maintenance
          charges, depository participant (DP) charges, SEBI Charges, GST, etc.
          Profitable companies regularly give dividends to shareholders,
          generating extra income streams apart from capital gains compared to
          buying property.<br></br>
          <br></br>
          In real estate, recurring costs are usually in thousands or lakhs,
          including maintenance, property taxes, utility costs, etc., further
          driving up ownership costs.<br></br>
          <br></br>
          <span style={{ fontWeight: "bold" }}>Liquidity</span> Stocks can be
          sold at very short notice without many hiccups at the prevailing
          market rate in just a few clicks. Whereas selling a house is a lengthy
          process. You must find a buyer, check the market value, agree on the
          selling price, and complete the registration process. The entire
          process can take up months to complete. Needless to say, the high
          brokerage charges also eat your profit margin.<br></br>
          <br></br>
        </p>
        <h2 id="section-9" style={{ fontWeight: "bold", margin:'0 2rem' }}>
          Returns on Investment: Stocks vs. Real Estate{" "}
        </h2>
        <br></br>
        <br></br>
        <p style={{margin:'0 2rem'}}> 
          The big question is, what is the return on investment on both asset
          classes over 20 years? First, let’s check a few calculations.<br></br>
          <br></br>
          <span style={{ fontWeight: "bold" }} id="section-10">
            Returns in equity stocks
          </span>
          <br></br>
          <br></br>
          While it is difficult to accurately determine the return on equity
          investment due to price volatility, stocks tend to outperform other
          asset classes by a significant margin over the long term. For
          instance, Sensex, the barometer of the Indian stock market has grown
          at a CAGR of over 14% over the past 20 years. And, in 20 years,
          Nifty50 has given negative returns only four times. Therefore, we will
          take a conservative annual return percentage of 12% to calculate the
          return on investment from equity stocks.
          <br></br>
          <br></br>
          On an investment of ₹ 10 lakh over 20 years, the sum will compound to
          ₹ 96.46.89 lakh at a 12% annual rate of return. Further, if you invest
          ₹10,000 each month for the next 20 years, your total investment of ₹
          24 lakh would grow close to ₹1.82 crores at 12% per annum. You can
          check the estimated returns on your investments using an online
          compound interest calculator or SIP calculator.<br></br>
          <br></br>
          <span style={{ fontWeight: "bold" }} id="section-11">
            Returns in real estate
          </span>
          <br></br>
          <br></br>
          As the return on investment in real estate varies significantly
          depending on location and quality of construction, it becomes
          difficult to determine the actual return on investment from buying a
          house. Therefore, we will take returns on REITs as our reference
          point.<br></br>
          <br></br>
          REIT, introduced in 2019 in India, currently offers yields in the
          range of 5.1-5.5%. REITs or Real Estate Investment Trusts are modeled
          on the lines of mutual funds that own and operate real estate to
          generate income. For instance, companies managing REITs lease out
          properties and collect rent, which is distributed to investors as
          dividends after deducting all the expenses.<br></br>
          <br></br>
          The investment of ₹ 1 lakh in REIT will be worth around ₹3.20 lakh at
          a 6% rate of return in the next 20 years. As per the CEIC data, d
          between March 2011 and September 2022, the average house price growth
          in India recorded was 5.1%.
        </p>
        <br></br>
        <br></br>
        <div class="row">
          <div class="col-md-12 col-12">
            <img src={someArticles} style={{width:'100%'}} alt="someArticles" />
          </div>
        </div>
        <br></br>
        <br></br>
        <p style={{margin:'0 2rem'}}>
          Also, if you are buying a house to live in, it is not considered an
          investment because no monetary benefits are associated.
          <br></br>
          <br></br>
          <span style={{ fontWeight: "bold" }} id="section-12">
            Cost of Buying a House On A Home Loan
          </span>
          <br></br>
          <br></br>
          Many tend to invest in house property by taking a home loan and
          repaying the loan amount through equal monthly installments (EMIs)
          over a long period. However, it pushes up the cost of ownership
          significantly.<br></br>
          <br></br>
          For instance, on a home loan of ₹25 lakh for a tenure of 20 years with
          an 8.20% interest rate, the monthly home loan EMI comes out at ₹
          21,223. Therefore, the total amount payable during the period will be
          ₹ 50.93 lakh, more than the value of the home loan.
          <br></br>
          <br></br>
          However, instead of taking a home loan, if you do a stock SIP of ₹
          21,223 for the next 20 years at a 12% rate of return, the investment
          would grow to ₹1.85.1 crores.
          <br></br>
          <br></br>
          <span style={{ fontWeight: "bold" }} id="section-13">
            Conclusion: Should You Invest In Stocks or Buy A House
          </span>
          <br></br>
          <br></br>
          As stated earlier, your decision to invest in stocks or buy a house
          entirely depends on your financial objectives and risk appetite.
          Buying a home as an investment may look attractive as it creates a
          physical asset. Still, the associated costs and low rate of return
          make it an unattractive investment option over the long term.
          <br></br>
          <br></br>
          As a sensible investor, you should not reject or fall in love with any
          particular asset class. On the contrary, if the asset class aligns
          with your financial objective, you should consider investing in it.
          But, for wealth creation over the long term, the economics and the
          past returns on investment suggest equity is better.
          <br></br>
          <br></br>
        </p>
        <h2 id="section-14" style={{ fontWeight: "bold", margin:'0 2rem' }}>
          FAQs{" "}
        </h2>
        <br></br>
        <br></br>
        <p style={{margin:'0 2rem'}}>
          <span id="section-15" style={{ fontWeight: "bold" }}>
            Should I invest in stocks or real estate?
          </span>
          <br></br>
          <br></br>
          The decision must be aligned with your financial objectives and goals.
          But, for wealth creation over the long term, the economics and the
          past returns on investment suggest equity is better. <br></br>
          <br></br>
          <span style={{ fontWeight: "bold" }} id="section-16">
            What are the disadvantages of investing in real estate?
          </span>{" "}
          <br></br>
          <br></br>
          Some of the major disadvantages are: -A considerable upfront
          investment, – Illiquid, – High transaction costs, and – Recurring
          costs. <br></br>
          <br></br>
          <span style={{ fontWeight: "bold" }} id="section-17">
            How do you invest in stocks?
          </span>{" "}
          <br></br>
          <br></br>
          You can invest in stocks by either directly purchasing them from the
          stock market via a broker or investing in equity-linked mutual funds.
        </p>
        <h1 class="topicsOurInfo" id="section-2" style={{margin:'0 2rem'}}>
          <span>2.</span>Should You Invest In Auto Stocks In 2023?
        </h1>{" "}
        <br></br> <br></br> <br></br>
        <ul
          style={{
            backgroundColor: "#f2f2f2",
            padding: "10px 10px",
            fontSize: "18px",
          }}
        >
          <li style={{ listStyle: "none" }}>
            <p onClick={handleClickScroll17}  style={{margin:'0 2rem'}} class="curserPointer">Overview of Auto Stocks</p>
            <ul style={{margin:'0 2rem'}}>
              <li onClick={handleClickScroll18} class="curserPointer">
                How Auto Stocks Performed in 2022
              </li>
              <li onClick={handleClickScroll19}  class="curserPointer">
                Why are Auto Stocks in Focus of Investors Worldwide?
              </li>
              <li onClick={handleClickScroll20}  class="curserPointer">
                ICE to Electric Transmission in India
              </li>
              <li onClick={handleClickScroll21}  class="curserPointer">
                Road Ahead for Auto Stocks in India
              </li>
              <li onClick={handleClickScroll22}  class="curserPointer">
                Auto Stocks to be in Focus in 2023
              </li>
              <li onClick={handleClickScroll23}  class="curserPointer">Conclusion</li>
            </ul>
          </li>
          <br></br>

          <li style={{ listStyle: "none" }}>
            <p onClick={handleClickScroll24}  style={{margin:'0 2rem'}} class="curserPointer">FAQs</p>
            <ul style={{margin:'0 2rem'}}>
              <li onClick={handleClickScroll25}  class="curserPointer">Are auto stocks cyclical</li>
              <li onClick={handleClickScroll26}  class="curserPointer">
                Which auto stocks gained the most in 2022?
              </li>
              <li onClick={handleClickScroll27}  class="curserPointer"> 
                Which auto company is the leader in the 4W EV segment?
              </li>
            </ul>
          </li>
        </ul>
        <br></br>
        <br></br>
        <p style={{margin:'0 2rem'}}>
          <h2 style={{ fontWeight: "bold"}} id="section-18">
            Overview of Auto Stocks{" "}
          </h2>
          <br></br>
          Since 2020, automakers worldwide have sacrificed sales volumes due to
          chip shortages. So acute was the shortage that car manufacturers had
          to cut back on production and were restricted to producing fewer
          variants of car models. For instance, Jaguar Land Rover, in response
          to the challenge, focused on high-margin products to reduce the
          financial impact.<br></br>
          <br></br>
          Due to the chip shortage, global auto companies lost $210 bn in
          revenue, losing 7.7 mn vehicles in production in 2021. And 2022 was
          the same as companies struggled to match demand. According to the
          Federation of Automobile Dealers Association (FADA), best-selling car
          models came with an average waiting period of 6-12 months.<br></br>
          <br></br>
          So, after two years of facing severe headwinds in the form of chip
          shortages, inflation, a fractured global supply chain, and rapidly
          changing customer preferences, are auto stocks looking good in 2023?
          Should you invest in them? Let’s find out.<br></br>
          <br></br>
          <span style={{ fontWeight: "bold" }} id="section-19">
            How Auto Stocks Performed in 2022
          </span>
          <br></br>
          <br></br>
          Compared to other sectors of the economy, auto manufacturers recorded
          decent business performance with improving sales in 2021. So, the
          Nifty Auto Index rewarded investors for the third consecutive year,
          rising 15.3% in 2022, outperforming the broader Nifty 50 index.
          <br></br>
          <br></br>9 out of 10 stocks in the Nifty Auto index settled 2022 in
          green, with Mahindra & Mahindra and Tube Investments leading the
          chart.<br></br>
          <br></br>
        </p>
        <div>
          <img style={{width:'100%'}} src={nifty} alt="nifty" />
        </div>
        <br></br>
        <br></br>
        <p style={{margin:'0 2rem'}}>
          A disrupted global supply chain resulting in runaway inflation and
          higher crude prices was a concern in the first half of 2022. A sharp
          correction in crude and metal prices supported the auto stocks.
          <br></br>
          <br></br>
          <span style={{ fontWeight: "bold" }} id="section-20">
            Why are Auto Stocks in Focus of Investors Worldwide?
          </span>
          <br></br>
          <br></br>
          The global automobile sector is rapidly transitioning from the
          internal-combustion engine (ICE) to battery-electric vehicles. And the
          transition has opened up new investment opportunities in the sector. A
          bit of background about the quick transition to electric mobility
          worldwide.<br></br>
          <br></br>
          On 4th November 2016, The Paris Climate Agreement came into force. 194
          countries, including India, signed it. The PCA is a legally binding
          international treaty to strengthen global response towards climate
          change and keep global temperature rise below 2 degrees Celsius above
          preindustrial levels. The countries also agreed to make efforts to
          limit the temperature increase to 1.5 degrees Celsius.<br></br>
          <br></br>
          And the key to meeting the commitments in the Paris Climate Agreement
          is cutting down on fossil fuel consumption and reducing greenhouse gas
          emissions. According to iea.org, the transport sector has the highest
          reliance on fossil fuels, accounting for 37% of the CO2 emissions in
          2021.<br></br>
          <br></br>
          Therefore, governments are making efforts through policy schemes to
          make a quick transition to emobility and increase the share of
          renewable energy sources to reduce the impact of climate change
          globally.<br></br>
          <br></br>
          <span style={{ fontWeight: "bold" }} id="section-21">
            ICE to Electric Transmission in India
          </span>
          <br></br>
          <br></br>
          The transition from ICE to Electric transmission in India was slow
          during the initial years but picked pace in the last year. Between
          2008-2021 total EV registrations stood at 7.3 lakh units (2W, 3W, 4W
          combined), whereas, in 2022, total EV registrations reached close to 6
          lakh units.<br></br>
          <br></br>
          Concerns like range anxiety, lack of road charging infrastructure, and
          higher price compared to ICE vehicles are now a thing of the past.
          With improved battery technology and charging infrastructure, EVs are
          now finding greater acceptance in society.<br></br>
          <br></br>
          The central government’s FAME and the state government’s multiple EV
          programs make owning EVs affordable, resulting in greater EV
          penetration. The 3W category is witnessing the fastest transition,
          followed by 2W and 4W. In the 4W EV category, Tata Motors is the
          leader, with over 80% market share in 2022.<br></br>
          <br></br>
          <span style={{ fontWeight: "bold" }} id="section-22">
            Road Ahead for Auto Stocks in India
          </span>
          <br></br>
          <br></br>
          Auto stocks are going through a transition phase, and stocks focusing
          more on EV play are benefitting. With improving chip supply, auto
          companies can focus on increasing production and sales revenue.
          <br></br>
          <br></br>
          In Q3 FY23, Tata Motors reported a 15% rise in wholesale volumes in
          Jaguar Land Rover on improved chip supply, indicating the worst days
          for the auto industry are behind. With the EV transition, there exists
          a massive chance of wealth creation by investing in the EV theme and
          selecting the right stock. However, the rising interest rate is
          negative for the sector as it translates into a higher price for
          vehicles, thus impacting demand.<br></br>
          <br></br>
          <span style={{ fontWeight: "bold" }} id="section-23">
            Auto Stocks to be in Focus in 2023
          </span>
          <br></br>
          <br></br>
          In the emergence of the EV revolution, auto ancillary, battery
          technology, and manufacturers, auto OEM stocks will be in focus in
          2023. The following are some of the auto stocks that will be in focus
          in 2023:<br></br>
          <br></br>
          <span style={{ fontWeight: "bold" }}>Tata Motors: </span> Tata Motors
          is one of the multibaggers’ of recent time in the auto industry,
          rising from ₹64 on 30th March 2020 to a peak of ₹536 on 15th Nov 2021.
          The stock has underperformed in the last few quarters but remains in
          investors’ focus due to its strong EV play. And the turnaround in the
          Jaguar Land Rover (JLR) segment could help Tata Motors’ share price
          move north.<br></br>
          <br></br>
          <span style={{ fontWeight: "bold" }}>Ashok Leyland:</span> Ashok
          Leyland is one of India’s largest commercial vehicle manufacturers and
          has also doubled its play in the EV segment. The company plans to make
          its EV arm- Switch Mobility, a global EV company and is positioning
          itself at the forefront of the commercial EV market.<br></br>
          <br></br>
          <span style={{ fontWeight: "bold" }}>TVS Motor:</span> Among all the
          2W listed companies, TVS Motor is one of the top-selling EV
          manufacturers in India. In December 2022, the company sold 11,071
          units of electric two-wheelers, and the stock price has grown over 70%
          in the last year.<br></br>
          <br></br>
          <span style={{ fontWeight: "bold" }}>Tube Investments (TII):</span> In
          the auto ancillary segment, TII is one of the best-performing stocks,
          with a 58.17% return in the last year and a 78.25% CAGR return in the
          previous three years. The company is a tier-I supplier to auto majors
          in India and has also invested in the clean mobility segment. In
          November 2022, it acquired a 50% stake in green energy startup X2
          Fuels, the company engaged in converting waste to fuel.<br></br>
          <br></br>
          <span style={{ fontWeight: "bold" }}>Amara Raja Batteries:</span>{" "}
          Under its flagship brand, Amaron, the company has made many strategic
          investments in shoring up its EV battery manufacturing capacity.
          Although the stock has underperformed, it continues to stay in focus
          as demand for EVs grows.<br></br>
          <br></br>
          You may want to check other auto stocks: Mahindra & Mahindra, Bajaj
          Auto, Samvardhana Motherson International Ltd., Sona Comstar, Exide,
          Hero Moto Corp, Bharat Electronics, Tata Power, etc.<br></br>
          <br></br>
          <span style={{ fontWeight: "bold" }} id="section-24">
            Conclusion
          </span>
          <br></br>
          <br></br>
          With strong play in the EV theme, increasing automobile exports,
          better visibility of chip supply, and softer metal prices will benefit
          the auto stocks in 2023. However, you must be careful with your
          investments as global uncertainty remains high amid war and fears of
          an impending recession in the US and Europe that can impact auto
          stocks.<br></br>
          <br></br>
          <span style={{ fontWeight: "bold", backgroundColor: "red" }}>
            Disclaimer*: The numbers mentioned in this article are for
            information purposes only. He/she should not consider this a
            buy/sell/hold recommendation from Research & Ranking. The company
            shall not be liable for any losses that occur.
          </span>
          <br></br>
          <br></br>
        </p>
        <p style={{ fontWeight: "bold", margin:'0 2rem' }} id="section-25">
          FAQs
        </p>
        <ul style={{margin:'0 2rem'}}>
          <li id="section-26">
            Are auto stocks cyclical?
            <ul>
              <p>
                Yes, auto stocks are cyclical, as households and businesses can
                defer purchases if they are short of cash.
              </p>
            </ul>
          </li>
          <li id="section-27">
            Which auto stocks gained the most in 2022?
            <ul>
              Mahindra & Mahindra, Tube Investments, are some of the
              best-performing auto stocks of 2022.
            </ul>
          </li>
          <li id="section-28">
            Which auto company is the leader in the 4W EV segment?
            <ul>
              Tata Motors is the leader in the 4W EV segment in India, with over
              80% market share.
            </ul>
          </li>
        </ul>
        <h1 class="topicsOurInfo" style={{margin:'0 2rem'}}>
          <span id="section-3">3.</span>How to Invest in Share Market
        </h1>
        <br></br> <br></br> <br></br>
        <p style={{margin:'0 2rem'}}>
          Investing in the share market can be an excellent way to grow your
          wealth over time. Here are some general steps to follow when investing
          in the share market:<br></br>
        </p>
        <div class="thirdtopicul" style={{margin:'0 2rem'}}>
          <ul>
            <li>
              Educate yourself: Before investing, it's essential to learn as
              much as you can about the stock market, how it works, and the
              various investment options available. You can read books,
              articles, watch videos, or take courses to improve your knowledge.
            </li>
            <li>
              Choose a broker: To invest in the stock market, you'll need to
              open an account with a brokerage firm. Look for a reputable and
              reliable broker with low fees and excellent customer service.
            </li>
            <li>
              Create a trading account: Once you've chosen a broker, you'll need
              to create a trading account. This will allow you to buy and sell
              stocks on the stock exchange.
            </li>
            <li>
              Decide on your investment strategy: Determine your investment
              goals, risk tolerance, and investment horizon. Your strategy could
              involve investing in individual stocks or mutual funds.
            </li>
            <li>
              Research companies: When investing in individual stocks, it's
              essential to research the companies you're interested in
              thoroughly. Consider the company's financial performance,
              earnings, and future growth prospects.
            </li>
            <li>
              Buy stocks: Once you've identified the stocks you want to invest
              in, place an order through your broker. You can buy stocks using
              market orders, limit orders, or stop orders.
            </li>
            <li>
              Monitor your portfolio: It's essential to keep an eye on your
              portfolio and adjust your investments as needed. Be sure to
              regularly review your investments and make changes to your
              portfolio as your investment goals and circumstances change.
            </li>
          </ul>
        </div>
        <p style={{margin:'0 2rem'}}>
          Remember that investing in the share market involves risks, and there
          are no guarantees of returns. It's essential to diversify your
          portfolio, be patient, and focus on long-term growth rather than
          shortterm gains.
        </p>
        <div>
          <h1 class="topicsOurInfo" style={{margin:'0 2rem'}}>
            <span id="section-4">4.</span>Shocking But True: 90% of People Lose
            Money In Stocks
          </h1>{" "}
          <br></br> <br></br> <br></br>
          <p style={{margin:'0 2rem'}}>
            There is an old but famous joke about stocks and the stock market.
            If you have lost some money in the stock market and feel bad about
            it, don’t worry. Ask somebody you know about their losses in the
            stock market, and you will feel better about losing less.<br></br>
            <br></br>
            While this joke has been doing rounds for several decades now and is
            still quite relevant because there is absolutely no shortage of
            people who lose money in stock markets daily. According to popular
            estimates, as much as 90% of people lose money in stock markets,
            including both new and seasoned investors.<br></br>
            <br></br>
            Isn’t it shocking? But it is a fact. There are countless reasons why
            investors lose money in stock markets. Let’s take a detailed look at
            some of the top reasons.<br></br>
            <br></br>
          </p>
          <ul style={{margin:'0 2rem'}}>
            <li>
              Investing in the stock market based on rumors and stock tips
            </li>
            <li>Investing in penny stocks in the Indian share market</li>
            <li>Indulging in intraday and short-term trading</li>
            <li>Lack of patience while investing money in stock market</li>
            <li>Not investing in fundamentally sound businesses</li>
          </ul>
          <p style={{ fontWeight: "bold" }} style={{margin:'0 2rem'}}>
            Some essential pointers which will help you to create wealth:
          </p>
          <br></br>
          <br></br>
          <p style={{margin:'0 2rem'}}>
            <span style={{ fontWeight: "bold" }}>
              1. Investing in the stock market based on rumors and stock tips
            </span>
            <br></br>
            <br></br>
            Have you ever received an SMS which reads like this “Buy 1000
            quantity of XYZ stocks for xx price? Huge upside expected in one
            month as it will be acquired by ABC company” or “Invest in bulk in
            XYZ stock as the company to soon acquire exclusive distribution
            rights of ABC product or service. So buy now at a low price of xx to
            sell at a high price of xx x within a few months”?<br></br>
            <br></br>
            Such messages are deliberately sent through bulk SMS by fraudsters
            who operate as a stock market cartel with the sole intention of
            cheating innocent investors by trapping them into buying stocks with
            no fundamentals associated.<br></br>
            <br></br>
            Many investors, especially new ones, fall into the trap of investing
            based on stock tips given by others without realizing that the
            person offering the advice depends on others for it. And suppose
            stock tips from friends/relatives/colleagues were not enough in
            today’s digital world. In that case, there is a constant bombardment
            of information, including stock tips on social media, WhatsApp
            groups and business news channels. Click here to read about 3 wrong
            reasons to buy a stock.<br></br>
            <br></br>
            When we switch on a business news channel, we can see many
            self-proclaimed experts or hosts urging us to buy or sell stocks in
            the next millisecond, hoping to make good money. But unfortunately,
            it creates a dangerous trap for innocent investors, who often
            mistake this constant flow of stock tips as genuine and invest based
            on it without understanding the reality.<br></br>
            <br></br>
            The pitfalls of stock tips can be best illustrated with the example
            of Infibeam Avenues. The stock of Infibeam Avenues fell by about 71%
            on 28th September 2018 from around Rs. 197 to around Rs. 50 after a
            WhatsApp message circulated in a trader’s group sparked panic among
            investors who went on a selling spree.<br></br>
            <br></br>
            The damage was so severe that the company’s MD had to issue a
            statement “Some WhatsApp rumours took rounds in the market, and it
            created panic among market participants and investors at large. We
            categorically rescind such stories which are erroneous and
            motivated.<br></br>
            <br></br>
            It is said that “Bad news is usually good news for somebody else”.
            It is 100% true about the news we hear about equities. Often, a
            fabricated report is deliberately released through media by some
            entities with dubious interests because they want the viewers to
            believe that the news offers them some competitive advantage.
            <br></br>
            <br></br>
            To quote a real-life example, the stock of Graphite India was a hot
            pick on most business news channels and websites in September 2018,
            with a potential upside of Rs. 400 from the then market price of Rs.
            1,00. However, four months later, the same business news channels
            and websites gave a sell call for Graphite India, with a target of
            Rs. 53. Currently, the stock is trading at Rs. 181 levels. Can you
            imagine the kind of loss an investor would have incurred if he had
            purchased the stock of Graphite India based on buy recommendations
            given by the business news channels and websites?<br></br>
            <br></br>
            Always remember that not all information is relevant information.
            <br></br>
            <br></br>
            <span style={{ fontWeight: "bold" }}>
              2. Investing in penny stocks in the Indian share market
            </span>
            <br></br>
            <br></br>
            As the name suggests, penny stocks trade at low prices, usually in
            the single digits or even lower. The low cost is what makes them
            attractive to some investors. However, investors often forget that
            price and value are two different things in this process.<br></br>
            <br></br>
            Penny stocks have low market capitalization, and very little
            information is available in the public domain. As a result, they are
            highly susceptible to management fraud and financial mismanagement.
            Investing in penny stocks is like dumping your money in the drain.
            Unfortunately, some investors learn this the hard way. Prakash
            Steelage, Lanco Infratech, Gemini Communication and Birla Power
            Solutions are some prime examples of penny stocks that destroyed
            over 75 – 90% of investor wealth.<br></br>
            <br></br>
            <span style={{ fontWeight: "bold" }}>
              3. Indulging in intraday and short-term trading
            </span>
            <br></br>
            <br></br>
            Many people indulge in short-term trading because it gives them a
            thrill, a sense of adventure and a feeling that they can become rich
            quickly. But trust me, if you want excitement, go to Vegas. It is
            because wealth creation is a tedious and lengthy process.<br></br>
            <br></br>
            Generally, people associate success with initial one or two trades,
            and this misconception goes for more transactions with a higher
            margin. However, in this process, they fail to realize that losses
            on one failed transaction could potentially wipe out all their
            previous gains.<br></br>
            <br></br>
            To make matters worse, people who lose money in intraday trading
            often resort to revenge trading to recover their losses by looking
            at the market as an enemy which imposes unjustified losses. In this
            process, their emotions take over the rational thought process,
            which results in additional losses. Revenge trading is one of the
            underlying reasons why many traders lose their entire capital.
            <br></br>
            <br></br>
            <span style={{ fontWeight: "bold" }}>
              4. Lack of patience while investing money in stock market
            </span>
            <br></br>
            <br></br>
            Patience is one of the greatest virtues required for long-term
            investing. Investors who understand the value of patience gain from
            it while those who don’t pay a heavy price.<br></br>
            <br></br>
            In the short term, markets and stock prices are affected by news and
            emotion. In addition, any economic, global or political changes can
            impact share markets in the short term. However, in the long term,
            stock prices are governed by the fundamentals of the business and
            its earnings.<br></br>
            <br></br>
            Many investors invest in good stocks but panic and sell them at the
            first sign of correction, resulting in a ‘Buy high and sell low’
            situation. Precisely the opposite of what they should do, i.e. ‘Buy
            low and sell high.’<br></br>
            <br></br>
            “Successful Investing takes time, discipline and patience. No matter
            how great the talent or effort, some things take time: You can’t
            produce a baby in one month by getting nine women pregnant.” –
            Warren Buffett.<br></br>
            <br></br>
            <span style={{ fontWeight: "bold" }}>
              5. Not investing in fundamentally sound businesses
            </span>
            <br></br>
            <br></br>
            Fundamentally sound stocks have a transparent and robust business
            model and are professionally well-managed. As a result, such
            companies can survive any economic downturns and are usually the
            first to recover and outperform when the economy improves.<br></br>
            <br></br>
            As you might be aware, 2008 witnessed one of the worst corrections
            in the history of the stock market. Many investors panicked and sold
            their investments for heavy losses as if there was no tomorrow.
            However, those who remained invested were rewarded immensely as the
            market recovered in less than 24 months.<br></br>
            <br></br>
            Again this is what happened to post the severe correction in March
            2020, where indices fell sharply after the emergence of the Covid-19
            pandemic. However, markets were quick to recover, and Indian indices
            touched new lifetime highs in fewer than 11 months.<br></br>
            <br></br>
            As you see, there are many reasons why 90% of investors lose money
            in the stock market. Forget profits; most of them even end up losing
            their entire capital and blaming the market or their luck for their
            financial misfortune. Successful investing in equity is no rocket
            science. It’s all about investing right, being patient and avoiding
            costly mistakes.<br></br>
            <br></br>
            Some essential pointers which will help you to create wealth:
            <br></br>
            <br></br>{" "}
          </p>
          <ul>
            <li>
              Buy in tranches: Use every market correction as an opportunity to
              buy at discounted prices.
            </li>
            <li>
              The stock-specific approach works best: Identify quality
              businesses and start accumulating them in small quantities.
            </li>
            <li>Think long-term: Invest for at least 3-5 years or more.</li>
            <li>
              Maintain your focus: Don’t get distracted by too much information
              or rumors you may receive via WhatsApp/Twitter/news channels.
            </li>
            <li>
              Don’t try to time the market: Don’t try to time the market by
              attempting to catch the highs or lows.
            </li>
          </ul>
        </div>
        <p style={{margin:'0 2rem'}}>
          <h1 class="topicsOurInfo" >
            <span id="section-5">5.</span>What Are Alternative Investment Funds-
            A Complete Guide
          </h1>{" "}
          <br></br> <br></br> <br></br>
          Alternative investment funds (AIFs) are investment vehicles that are
          not regulated by traditional investment structures such as mutual
          funds or exchange-traded funds. AIFs typically invest in assets or
          strategies that are less liquid or less well-known than traditional
          investment vehicles.<br></br>
          <br></br>
          Some common examples of AIFs include hedge funds, private equity
          funds, real estate funds, and venture capital funds. AIFs are
          typically open only to accredited investors or institutional investors
          due to their high minimum investment requirements and complex
          investment strategies.<br></br>
          <br></br>
          Here is a complete guide to understanding AIFs:<br></br>
          <br></br>
        </p>
        <div class="thirdtopicul">
          <ul style={{margin:'0 2rem'}}>
            <li>
              Objectives: AIFs aim to provide investors with unique investment
              opportunities that are not available through traditional
              investment vehicles. AIFs can provide investors with exposure to
              alternative asset classes, such as commodities, real estate, or
              derivatives, that can potentially generate high returns.
            </li>
            <li>
              Types of AIFs: There are several types of AIFs, including hedge
              funds, private equity funds, real estate funds, and venture
              capital funds. Each type of AIF has its unique investment
              strategy, risk profile, and target return.
            </li>
            <li>
              . Investment strategies: AIFs use various investment strategies,
              such as long/short equity, event-driven, distressed debt, and
              global macro, to generate returns. Some AIFs may use leverage or
              employ derivatives to enhance returns or manage risk.
            </li>
            <li>
              Regulation: AIFs are typically less regulated than traditional
              investment vehicles, such as mutual funds or ETFs. However, AIFs
              are subject to regulations under the Alternative Investment Fund
              Managers Directive (AIFMD) in the European Union and the
              Securities and Exchange Commission (SEC) in the United States.
            </li>
            <li>
              Risks: AIFs can be riskier than traditional investments due to
              their complex investment strategies, lack of transparency, and
              less liquid investments. Investors should be prepared for the
              possibility of losing some or all of their investment.
            </li>
            <li>
              Minimum investment: AIFs typically have a high minimum investment
              requirement, which can make them accessible only to accredited
              investors or institutional investors.
            </li>
            <li>
              Fees: AIFs typically charge higher fees than traditional
              investment vehicles, such as mutual funds or ETFs. These fees can
              include management fees, performance fees, and other expenses.
            </li>
          </ul>
          <br></br>
          <br></br>
          <p style={{margin:'0 2rem'}}>
            In conclusion, AIFs can be an excellent investment opportunity for
            investors who are looking for unique investment opportunities and
            are willing to accept higher risks and costs. However, investors
            should carefully consider their investment objectives, risk
            tolerance, and financial situation before investing in AIFs. It's
            always essential to seek professional financial advice before making
            any investment decisions.
          </p>
        </div>
        <br></br>
        <br></br>
      </div>
    </div>
  );
}

export default SomeArticles;
