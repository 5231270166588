import logo from "./logo.png";
import "./Footer.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import React, { useState } from "react";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaYoutubeSquare,
} from "react-icons/fa";

const Footer = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [showMediaIcons, setShowMediaIcons] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [activeItem, setActiveItem] = useState("");
  return (
    <footer id="footer" class="footer-1">
      <div class="main-footer widgets-dark typo-light">
        <div class="FooterMain"  style={{fontSize:'16px'}}>
          <div class="row d-flex">
            <div class="col-xs-12 col-lg-3 col-xl-3 col-sm-6 col-md-6 FooterLeft">
              <div class="widget subscribe no-box">
                <div className="FooterLogo">
                  <img src={logo} alt="Logo" />
                </div>
                <br></br>
                <p class="footer-para">
                  The In-house "Harmonic Price & Time Study" is being worked on
                  by leading research analysts. In contrast to other analysts'
                  laggard indicators which are most commonly used in the
                  industry, this method is a "Lead" indicator.
                </p>
              </div>
            </div>

            <div class="col-xs-12 col-lg-3 col-xl-3 col-sm-6 col-md-6  FooterCenter">
              <div class="widget no-box">
                <h3 class="widget-title">
                  Links<span></span>
                </h3>
                <ul class="thumbnail-widget">
                  <li>
                    <div class="thumb-content">
                      <Link
                        to="/About"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "auto",
                          });
                        }}
                      >
                        {" "}
                        About
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div class="thumb-content">
                      <Link
                        to="/Services"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "auto",
                          });
                        }}
                      >
                        Services
                      </Link>
                    </div>
                  </li>

                  <li>
                    <div class="thumb-content">
                      <Link
                        to="/ClassesWebinar"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "auto",
                          });
                        }}
                      >
                        Class & Webinar
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div class="thumb-content">
                      <Link
                        to="/OurInfo"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "auto",
                          });
                        }}
                      >
                        Our Info
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div class="thumb-content">
                      <Link
                        to="/ContactUs"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "auto",
                          });
                        }}
                      >
                        Contact Us
                      </Link>
                    </div>
                  </li>
                </ul>
                <div className="" style={{fontSize:'18px'}}>
                <ul className="" style={{display:'flex'}}>
                  <li style={{padding:'0 5px 0 0 '}}>
                    <a
                      href=" https://www.facebook.com/Raghavoptions?mibextid=ZbWKwL"
                      target=""
                    >
                      <FaFacebookSquare className="facebook" />
                    </a>
                  </li>

                  <li style={{padding:'0 5px 0 0 '}}>
                    <a
                      href="https://instagram.com/raghavoptions?igshid=YmMyMTA2M2Y="
                      target=""
                    >
                      <FaInstagramSquare className="instagram" />
                    </a>
                  </li>
                  <li style={{padding:'0 5px 0 0 '}}>
                    <a
                      href="https://www.youtube.com/@raghavoptions7389"
                      target=""
                    >
                      <FaYoutubeSquare className="youtube" />
                    </a>
                  </li>
                </ul>
              </div>
              </div>
              
            </div>

            <div class="col-xs-12 col-lg-3 col-xl-3 col-sm-6 col-md-6  FooterCenter marginService">
              <div class="widget no-box">
                <h3 class="widget-title">
                  Services<span></span>
                </h3>
                <ul class="thumbnail-widget">
                  <li>
                    <div class="thumb-content">
                      <Link
                        to="/Services"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "auto",
                          });
                        }}
                      >
                        Equity Services
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div class="thumb-content">
                      <Link
                        to="/Services"
                        onClick={() => {
                          window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "auto",
                          });
                        }}
                      >
                        Derivative Service
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div
                      class="thumb-content"
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "auto",
                        });
                      }}
                    >
                      <Link to="/Services">Index Services</Link>
                    </div>
                  </li>
                  <li>
                    <div
                      class="thumb-content"
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "auto",
                        });
                      }}
                    >
                      <Link to="/Services">Commodity Services</Link>
                    </div>
                  </li>
                  <li>
                    <div
                      class="thumb-content"
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "auto",
                        });
                      }}
                    >
                      <Link to="/Services">Currency Services</Link>
                    </div>
                  </li>
                  <li>
                    <div
                      class="thumb-content"
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "auto",
                        });
                      }}
                    >
                      <Link to="/Services">Future & Options</Link>
                    </div>
                  </li>
                  <li>
                    <div
                      class="thumb-content"
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "auto",
                        });
                      }}
                    >
                      <Link to="/Services">Option Index HNI</Link>
                    </div>
                  </li>
                  <li>
                    <div
                      class="thumb-content"
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "auto",
                        });
                      }}
                    >
                      {" "}
                      <Link to="/Services">Wealth Management</Link>
                    </div>
                  </li>
                  <li>
                    <div
                      class="thumb-content"
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "auto",
                        });
                      }}
                    >
                      <Link to="/Services">Advisory</Link>
                    </div>
                  </li>
                  <li>
                    <div
                      class="thumb-content"
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "auto",
                        });
                      }}
                    >
                      <Link to="/Services">Portfolio Mangenment</Link>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div class="col-xs-12 col-lg-3 col-xl-3 col-sm-6 col-md-6  FooterRight">
              <div class="widget no-box">
                <h3 class="widget-title">
                  Get in touch<span></span>
                </h3>
                <p>
                  CLOVER SECURITY PVT LTD
                  <br></br>
                  Office No. 316, 3rd Floor<br></br>
                  Finswell Near Bajaj Finserv,<br></br>
                  Sakore Nagar,<br></br>
                  Vimannagar Pune - Maharashtra 411014
                </p>
                <p>Phone No :- +91 8975752441</p>
                <p>email id :- 9877@religare.in</p>
                <p>raghavoption9877@gmail.com</p>
                <p>Office Time:</p>
                <p>Monday to Friday</p>
                <p>9:00 AM TO 5:00 PM</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
