export const accordionData = [
    {
      title: 'Should I invest in stocks or real estate?',
      content: `The decision must be aligned with your financial objectives and goals. But, for wealth creation over
      the long term, the economics and the past returns on investment suggest equity is better.`
    },
    {
      title: 'What are the disadvantages of investing in real estate?',
      content: `Some of the major disadvantages are:
      -A considerable upfront investment,
      – Illiquid,
      – High transaction costs, and
      – Recurring costs.`
    },
    {
      title: 'How do you invest in stocks?',
      content: `You can invest in stocks by either directly purchasing them from the stock market via a broker or
      investing in equity-linked mutual funds.`
    }
    ,
   
];