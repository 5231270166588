import React from "react";
import "./RaghavOptionsServicesClassWebinar.css";
import Topic from "./topic.png";
import CurrencyMarket from "./CurrencyMarket.png";
import EquityMarket from "./EquityMarket.png";
import DerivativesMarket from "./DerivativesMarket.png";
import Fundamental from "./Fundamental.png";
import Demat from "./Demat.png";
import Technical from "./Technical.png";
import Trading from "./Trading.png";
import Mutual from "./Mutual.png";
import Portfolio from "./Portfolio.png";
import CommodityMarket from "./CommodityMarket.png";
import financially from "./financially.png";
import feature1 from "./feature1.png";
import feature2 from "./feature2.png";
import feature3 from "./feature3.png";
import feature4 from "./feature4.png";
import feature5 from "./feature5.png";
import feature6 from "./feature6.png";
import feature7 from "./feature7.png";
import retire from "./retire.png";
import WorkFromAnywhere from "./WorkFromAnywhere.png";
import GrowYourWealth from "./GrowYourWealth.png";
import BeYourOwn from "./BeYourOwn.png";
import Rectangle1 from "./Rectangle 421.png";
import Rectangle2 from "./Rectangle 422.png";
import Rectangle3 from "./Rectangle 423.png";
import Rectangle4 from "./Rectangle 424.png";
import aboutVideo from "./10.mp4";

function RaghavOptionsServicesClassWebinar() {
  return (
    <div>
      {/* banner part */}
      <div class="classWebinarBanner">
        <section class="serviceBannerImg banner-video">
          <section class="video-container">
            <video className="videoTag" autoPlay loop muted>
              <source src={aboutVideo} type="video/mp4" />
            </video>
            <div class="BannerText container">
              <h1 style={{ color: "black" }}>Classes & Webinar</h1>
            </div>
          </section>
        </section>
      </div>
      {/* Benefits of Share Market as a business */}
      <div class="classWebinarBenfits container">
        <h1 class="mainTitle">Benefits of Share Market as a business</h1>
        <div class="serviceFeatures">
          <div class="item item--1  box">
            <div className="optionIcon ">
              <img src={financially} alt="financially" />
            </div>
            <h3 class="title">
              <a href="">Become financially independent</a>
            </h3>
          </div>
          <div class="item item--2  box">
            <div className="optionIcon ">
              <img src={retire} alt="retire" />
            </div>
            <h3 class="title">
              <a href="">Chances to retire early</a>
            </h3>
          </div>
          <div class="item item--3  box">
            <div className="optionIcon">
              <img src={WorkFromAnywhere} alt="WorkFromAnywhere" />
            </div>
            <h3 class="title">
              <a href="">Work from anywhere in the world</a>
            </h3>
          </div>
          <div class="item item--4  box">
            <div className="optionIcon ">
              <img src={GrowYourWealth} alt="GrowYourWealth" />
            </div>
            <h3 class="title">
              <a href="">Grow your wealth</a>
            </h3>
          </div>
          <div class="item box">
            <div className="optionIcon">
              <img src={BeYourOwn} alt="BeYourOwn" />
            </div>
            <h3 class="title">
              <a href="">Be your own boss</a>
            </h3>
          </div>
        </div>
      </div>
      {/* Topic Covered */}
      <div class="topicCovered  ">
        <h1 class="mainTitle"> Topic Covered</h1>
        <div class="topicCoveredColumns container">
          <div class="row d-flex">
            <div
              class="col-md-12 col-lg-3 col-sm-12 "
              style={{
                backgroundImage: "url(" + Topic + ")",
                borderRadius: "30px",
                marginBottom: "2rem",
              }}
            ></div>
            <div class="col-md-6 col-lg-4 col-sm-12">
              <div class="topicCoveredBox">
                <div class="topicCoveredImg">
                  <img src={EquityMarket} alt="EquityMarket" />
                </div>
                <div class="topicName">
                  <p>Equity Market</p>
                </div>
              </div>
              <div class="topicCoveredBox">
                <div class="topicCoveredImg">
                  <img src={CurrencyMarket} alt="CurrencyMarket" />
                </div>
                <div class="topicName">
                  <p>Currency Market</p>
                </div>
              </div>
              <div class="topicCoveredBox">
                <div class="topicCoveredImg">
                  <img src={DerivativesMarket} alt="DerivativesMarket" />
                </div>
                <div class="topicName">
                  <p>Derivatives Market</p>
                </div>
              </div>
              <div class="topicCoveredBox">
                <div class="topicCoveredImg">
                  <img src={Fundamental} alt="Fundamental" />
                </div>
                <div class="topicName">
                <p>Demat account trading software</p>
              
                </div>
              </div>
              <div class="topicCoveredBox">
                <div class="topicCoveredImg">
                  <img src={Portfolio} alt="Portfolio" />
                </div>
                <div class="topicName">
                  <p>Portfolio management & Financial Planning</p>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-4 col-sm-12">
              <div class="topicCoveredBox">
                <div class="topicCoveredImg">
                  <img src={CommodityMarket} alt="CommodityMarket" />
                </div>
                <div class="topicName">
                  <p>Commodity Market</p>
                </div>
              </div>
              <div class="topicCoveredBox">
                <div class="topicCoveredImg">
                  <img src={Demat} alt="Demat" />
                </div>
                <div class="topicName">
                <p>Fundamental analysis</p>
                </div>
              </div>
              <div class="topicCoveredBox">
                <div class="topicCoveredImg">
                  <img src={Technical} alt="Technical" />
                </div>
                <div class="topicName">
                  <p>Technical analysis</p>
                </div>
              </div>
              <div class="topicCoveredBox">
                <div class="topicCoveredImg">
                  <img src={Trading} alt="Trading" />
                </div>
                <div class="topicName">
                  <p>Trading techniques & strategies</p>
                </div>
              </div>
              <div class="topicCoveredBox">
                <div class="topicCoveredImg">
                  <img src={Mutual} alt="Mutual" />
                </div>
                <div class="topicName">
                  <p>Mutual fund</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Course Features */}
      <div class="CourseFeatures container">
        <h1 class="mainTitle">Course Features</h1>
        <div class="classFeatures">
          <div class="row justify-content-center d-flex">
            <div class="col-md-3 col-sm-12 col-lg-3">
              <div class="item item--1  box">
                <div className="optionIcon ">
                  <img src={feature1} alt="feature1" />
                </div>
                <h3 class="title">
                  <a href="">Live Market Learning</a>
                </h3>
              </div>
            </div>
          </div>
          <div class="row d-flex">
            <div class="col-md-3 col-sm-12 col-lg-3">
              <div class="item item--1  box">
                <div className="optionIcon ">
                  <img src={feature2} alt="feature2" />
                </div>
                <h3 class="title">
                  <a href="">E-Learning Video Course</a>
                </h3>
              </div>
            </div>
            <div class="col-md-3 col-sm-12 col-lg-3">
              <div class="item item--1  box">
                <div className="optionIcon ">
                  <img src={feature3} alt="feature3" />
                </div>
                <h3 class="title">
                  <a href="">10 Tutorial Test For 10 Topics</a>
                </h3>
              </div>
            </div>
            <div class="col-md-3 col-sm-12 col-lg-3">
              <div class="item item--1  box">
                <div className="optionIcon ">
                  <img src={feature4} alt="feature4" />
                </div>
                <h3 class="title">
                  <a href="">Live Training with Q&A</a>
                </h3>
              </div>
            </div>
          </div>
          <div class="row d-flex">
            <div class="col-md-3 col-sm-12 col-lg-3">
              <div class="item item--1  box">
                <div className="optionIcon ">
                  <img src={feature5} alt="feature5" />
                </div>
                <h3 class="title">
                  <a href="">Customized online reading material/ notes</a>
                </h3>
              </div>
            </div>
            <div class="col-md-3 col-sm-12 col-lg-3">
              <div class="item item--1  box">
                <div className="optionIcon ">
                  <img src={feature6} alt="feature6" />
                </div>
                <h3 class="title">
                  <a href="">3 level Online exams (Basic, Advanced , Master)</a>
                </h3>
              </div>
            </div>
            <div class="col-md-3 col-sm-12 col-lg-3">
              <div class="item item--1  box">
                <div className="optionIcon ">
                  <img src={feature7} alt="feature7" />
                </div>
                <h3 class="title">
                  <a href="">
                    Software , Mobile App & Technical Charts Training
                  </a>
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Course Benefits */}
      <div class="CourseBenefits container">
        <h1 class="mainTitle">Course Benefits</h1>
        <div className="row">
          <div class="col-md-12 col-xl-6 col-lg-6 col-sm-12 webinarBenefits">
            <div>
              <img src={Rectangle2} alt="Rectangle2" />
            </div>
            <div>
              <p>
                Create a strong foundation of knowledge about the stock market.
              </p>
            </div>
          </div>
          <div class="col-md-12 col-xl-6 col-lg-6 col-sm-12 webinarBenefits">
            <div>
              <img src={Rectangle3} alt="Rectangle3" />
            </div>
            <div>
              <p>Make your own decisions regarding ‘Entry’ and ‘Exit’ rates.</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div class="col-md-12 col-xl-6 col-lg-6 col-sm-12 webinarBenefits">
            <div>
              <img src={Rectangle1} alt="Rectangle1" />
            </div>
            <div>
              <p>
                Practice self-independent trading & investing, without anyone’s
                help.
              </p>
            </div>
          </div>
          <div class="col-md-12 col-xl-6 col-sm-12 webinarBenefits">
            <div>
              <img src={Rectangle4} alt="Rectangle4" />
            </div>
            <div>
              <p>
                Earn an outstanding average profit of 10-15% easily! (Example:
                If you keep a margin of Rs. 1 Lakh, then you can earn a profit
                of 10-15% every month)
              </p>
            </div>
          </div>
        </div>
      </div>
      
      {/* Yotube Content */}
      <div style={{ padding: "5rem 0" }}>
        <h1 class="mainTitle" style={{ paddingBottom: "5rem" }}>
          YouTube Classes & Analysis Testimonial Video's
        </h1>
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-lg-6 col-xl-6 col-sm-12 ">
              <div class="card">
                <div class="card-image">
                  <div class="embed-responsive embed-responsive-16by9">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/kTuc2uR1M-8"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                    <div class="content">
                      <h1>NIFTY | BANKNIFTY</h1>
                    </div>
                    {/* <!--end div class content--> */}
                  </div>
                  {/* <!--End Div embed-responsive--> */}
                </div>
                {/* <!-- card image --> */}

                <div class="card-content">
                  <span class="card-title">TECHNICAL & OPTIONS DATA</span>
                </div>
                {/* <!-- card content --> */}
              </div>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-6 col-sm-12 ">
              <div class="card">
                <div class="card-image">
                  <div class="embed-responsive embed-responsive-16by9">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/nn8YYq9mtlE"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                    <div class="content">
                      <h1>HDFC AMC</h1>
                    </div>
                  </div>
                </div>
                {/* <!-- card image --> */}
                <div class="card-content">
                  <span class="card-title">TECHNICAL SETUP</span>
                </div>
                {/* <!-- card content --> */}
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
          <div class="row">
            <div class="col-md-12 col-lg-6 col-xl-6 col-sm-12 ">
              <div class="card">
                <div class="card-image">
                  <div class="embed-responsive embed-responsive-16by9">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/gCXt4xc6zq4"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                    <div class="content">
                      <h1>GUJARAT ELECTION</h1>
                    </div>
                  </div>
                </div>
                {/* <!-- card image --> */}

                <div class="card-content">
                  <span class="card-title">NIFTY | BANKNIFTY</span>
                </div>
                {/* <!-- card content --> */}
              </div>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-6 col-sm-12 ">
              <div class="card">
                <div class="card-image">
                  <div class="embed-responsive embed-responsive-16by9">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/QnWsop1Djyk"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                    <div class="content">
                      <h1>JINDAL STEEL & POWER LTD</h1>
                    </div>
                  </div>
                </div>
                {/* <!-- card image --> */}

                <div class="card-content">
                  <span class="card-title">INDIAN STOCKS REVIEW</span>
                </div>
                {/* <!-- card content --> */}
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
          {/* <!--end row--> */}

          <div class="row">
            <div class="col-md-12 col-lg-6 col-xl-6 col-sm-12 ">
              <div class="card">
                <div class="card-image">
                  <div class="embed-responsive embed-responsive-16by9">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/gEULPHVPLJE"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                    <div class="content">
                      <h1>NIFTY ANALYSIS | BANK NIFTY POST MARKET ANALYSIS</h1>
                    </div>
                  </div>
                </div>
                {/* <!-- card image --> */}

                <div class="card-content">
                  <span class="card-title">
                    JACKPOT EXPIRY TOMORROW | OPTION CHAIN ANALYSIS
                  </span>
                </div>
                {/* <!-- card content --> */}
              </div>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-6 col-sm-12">
              <div class="card">
                <div class="card-image">
                  <div class="embed-responsive embed-responsive-16by9">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/DZyP6cL2nNM"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                    <div class="content">
                      <h1>Bank Nifty</h1>
                    </div>
                  </div>
                </div>
                {/* <!-- card image --> */}

                <div class="card-content">
                  <span class="card-title">DISCUSSION ABOUT BANK NIFTY</span>
                </div>
                {/* <!-- card content --> */}
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
          {/* <!--end row--> */}

          <div class="row">
            <div class="col-md-12 col-lg-6 col-xl-6 col-sm-12 ">
              <div class="card">
                <div class="card-image">
                  <div class="embed-responsive embed-responsive-16by9">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/lTEFCeJu-sw"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                    <div class="content">
                      <h1>NIFTY50 and BANKNIFTY ANALYSIS</h1>
                    </div>
                  </div>
                </div>
                {/* <!-- card image --> */}

                <div class="card-content">
                  <span class="card-title">ICICIBANK RESULTS</span>
                </div>
                {/* <!-- card content --> */}
              </div>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-6 col-sm-12">
              <div class="card">
                <div class="card-image">
                  <div class="embed-responsive embed-responsive-16by9">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/kFXgTZlWq_Q"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                    <div class="content">
                      <h1>NIFTY50 & BANKNIFTY ANALYSIS</h1>
                    </div>
                  </div>
                </div>
                {/* <!-- card image --> */}
                <div class="card-content">
                  <span class="card-title">APRIL 2022</span>
                </div>
                {/* <!-- card content --> */}
              </div>
            </div>
          </div>
          <br></br>
          <br></br>
          <div class="row">
            <div class="col-md-12 col-lg-6 col-xl-6 col-sm-12">
              <div class="card">
                <div class="card-image">
                  <div class="embed-responsive embed-responsive-16by9">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/l5EmB-QtgnY"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                    <div class="content">
                      <h1>NIFTY50 & BANKNIFTY ANALYSIS </h1>
                    </div>
                  </div>
                </div>
                {/* <!-- card image --> */}

                <div class="card-content">
                  <span class="card-title">31st MARCH 2022</span>
                </div>
                {/* <!-- card content --> */}
              </div>
            </div>
            <div class="col-md-12 col-lg-6 col-xl-6 col-sm-12">
              <div class="card">
                <div class="card-image">
                  <div class="embed-responsive embed-responsive-16by9">
                    <iframe
                      width="560"
                      height="315"
                      src="https://www.youtube.com/embed/4TfWqjkG6Kc"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                    <div class="content">
                      <h1>NIFTY50 & BANKNIFTY ANALYSIS</h1>
                    </div>
                  </div>
                </div>
                {/* <!-- card image --> */}

                <div class="card-content">
                  <span class="card-title">10TH MARCH 2022</span>
                </div>
                {/* <!-- card content --> */}
              </div>
            </div>
          </div>
          {/* <!--end row--> */}
        </div>
      </div>
      {/* <!--end container--> */}
    </div>
  );
}

export default RaghavOptionsServicesClassWebinar;
