import React from "react";
import Equity from "./equity.png";
import "./RaghavOptionsServicesPage.css";
import Derivative from "./Derivative.png";
import Index from "./Index.png";
import Commodity from "./Commodity.png";
import Currency from "./Currency.png";
import Future from "./Future.png";
import Option from "./Option.png";
import Wealth from "./Wealth.png";
import Advisory from "./Currency.png";
import Portfolio from "./Portfolio .png";
import servicesVideo from "./11.mp4"
export default function () {
  return (
    <div>
      {/* banner part  */}
      <div class="servicePageBanner">
        <section class="serviceBannerImg banner-video">
          <section class="video-container">
            <video className="videoTag" autoPlay loop muted>
              <source src={servicesVideo} type="video/mp4" />
            </video>
            <div class="BannerText container">
              <h1 style={{color:'black'}}>Services</h1>
            </div>
          </section>
        </section>
      </div>
      {/* services */}
      <div class="servicesImg container">
        <h1 class="mainTitle">Services</h1>
        <div>
          <div class="serviceFeatures">
            <div class="item item--1  box">
              <div className="optionIcon ">
                <img src={Equity} alt="Equity" />
              </div>
              <h3 class="title">
                <a href="">Equity Services</a>
              </h3>
            </div>
            <div class="item item--2  box">
              <div className="optionIcon ">
                <img src={Derivative} alt="Derivative" />
              </div>
              <h3 class="title">
                <a href="">Derivative Service</a>
              </h3>
            </div>
            <div class="item item--3  box">
              <div className="optionIcon">
                <img src={Index} alt="Index Services" />
              </div>
              <h3 class="title">
                <a href="">Index Services</a>
              </h3>
            </div>
            <div class="item item--4  box">
              <div className="optionIcon ">
                <img src={Commodity} alt="Commodity Services" />
              </div>
              <h3 class="title">
                <a href="">Commodity Services</a>
              </h3>
            </div>
            <div class="item box">
              <div className="optionIcon">
                <img src={Currency} alt="Currency Services" />
              </div>
              <h3 class="title">
                <a href="">Currency Services</a>
              </h3>
            </div>
            <div class="item item--1  box">
              <div className="optionIcon ">
                <img src={Future} alt="Future & Option’s" />
              </div>
              <h3 class="title">
                <a href="">Future & Option’s</a>
              </h3>
            </div>
            <div class="item item--1  box">
              <div className="optionIcon ">
                <img src={Option} alt="Option Index HNI" />
              </div>
              <h3 class="title">
                <a href="">Option Index HNI</a>
              </h3>
            </div>
            <div class="item item--1  box">
              <div className="optionIcon ">
                <img src={Wealth} alt="Wealth Management" />
              </div>
              <h3 class="title">
                <a href="">Wealth Management</a>
              </h3>
            </div>
            <div class="item item--1  box">
              <div className="optionIcon ">
                <img src={Advisory} alt="Advisory" />
              </div>
              <h3 class="title">
                <a href="">Advisory</a>
              </h3>
            </div>
            <div class="item item--1  box">
              <div className="optionIcon ">
                <img src={Portfolio} alt="Portfolio Mangenment" />
              </div>
              <h3 class="title">
                <a href="">Portfolio Mangenment</a>
              </h3>
            </div>
          </div>
        </div>
      </div>
      {/* packages */}
      <div class="servicePackage container">
        <h1 class="mainTitle">Packages</h1>
        <div class="servicePakagesBox">
          <div class=" row rowContainer">
            <div class="col-md-6 col-lg-6 col-sm-12 boxContainer">
              <h2 style={{ color: "19024B", fontWeight: "bold" }}>Monthly</h2>
              <p
                style={{
                  color: "orangered",
                  fontWeight: "700",
                  fontSize: "18px",
                }}
              >
                ₹ 2999/-
              </p>
              <div class="servicePacakgesList">
                <ul>
                  <li>Get 2-3 Ideas Daily (Positional + Intraday)</li>
                  <li>Proper Entry & Exit Level.</li>
                  <li>Regular-Follow Up & Update.</li>
                  <li>Guidance via Option Risk Management.</li>
                  <li>
                    Proper Technical View. Logic & Level for Positional Trades.
                  </li>
                  <li>Objective to capture market momentum Up or Down</li>
                  <li>Accuracy 80% & above.</li>
                </ul>
              </div>
              <div class="justify-content-center">
                <a href="/ContactUs" target="_blank" type="button" class="boxButton">
                  Enroll Now
                </a>
              </div>
            </div>

            <div class="col-md-6 col-lg-6 col-sm-12 boxContainer">
              <h2 style={{ color: "19024B", fontWeight: "bold" }}>
                Half Yearly
              </h2>
              <p
                style={{
                  color: "orangered",
                  fontWeight: "700",
                  fontSize: "18px",
                }}
              >
                ₹ 14999/-
              </p>
              <div class="servicePacakgesList">
                <ul>
                  <li>Pre-Market View & Trading</li>
                  <li>Proper Entry & Exit Level.</li>
                  <li>Get 1-2 Ideas Daily (Positional + Intraday)</li>
                  <li>Proper Entry & Exit Level.</li>
                  <li>Regular Follow Up & Updates</li>
                  <li>Get Live Market Guidance & Risk-Management</li>
                  <li>Potential Profit Target 15k Per Trade</li>
                  <li>Objective to capture Index Swings</li>
                  <li>Accuracy 80% & Above</li>
                </ul>
              </div>
              <div class="justify-content-center">
                <a href="/ContactUs" target="_blank" type="button" class="boxButton">
                  Enroll Now
                </a>
              </div>
            </div>
          </div>
          <div class=" row rowContainer">
            <div class="col-md-6 col-lg-6 col-sm-12 boxContainer">
            <h2 style={{ color: "19024B", fontWeight: "bold" }}>Yearly</h2>
            <p
              style={{
                color: "orangered",
                fontWeight: "700",
                fontSize: "18px",
              }}
            >
              ₹ 25999/-
            </p>
            <div class="servicePacakgesList">
              <ul>
                <li>Best Filtered trade In Index OR Stock Trades</li>
                <li>Get 2-3 Positional Trades</li>
                <li>Get 3-4 Intraday Index/ Stock trades</li>
                <li>Index/ Stock Option Trade</li>
                <li>Customize Package as per Client Requirement</li>
                <li>Objective wealth Creation Accuracy 90% & Above</li>
              </ul>
            </div>
            <div class="justify-content-center">
              <a href="/ContactUs" target="_blank" type="button" class="boxButton">
                Enroll Now
              </a>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-sm-12 boxContainer">
            <h2 style={{ color: "19024B", fontWeight: "bold" }}>
              Personal Account Handling
            </h2>
            <p
              style={{
                color: "orangered",
                fontWeight: "700",
                fontSize: "18px",
              }}
            >
              Margin Required Min ₹ 25lk
            </p>
            <div class="servicePacakgesList">
              <ul>
                <li>
                  Personalized Service by our Head Research Analyst Discuss &
                  plan a strategy for
                </li>
                <li>Personal guidance during market hours</li>
                <li>Best Options Strategies & Hedging</li>
                <li>Minimum Risk & Maximum Return Positional Strategies</li>
                <li>Pair Trading</li>
                <li>Objective utmost satisfaction & Wealth Creation</li>
              </ul>
            </div>
            <div class="justify-content-center">
              <a href="/ContactUs" target="_blank" type="button" class="boxButton">
                Enroll Now
              </a>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}
