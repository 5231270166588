import React from "react";
import "./RaghavOptionsContactUs.css";
import { Link } from "react-router-dom";
import Contact from "./contactForm";
import contactVideo from "./6.mp4";
function RaghavOptionsContactUs() {
  return (
    <div>
      {/* banner part */}
      <div class="ContactUsBanner font">
        <section class="serviceBannerImg banner-video">
          <section class="video-container">
            <video className="videoTag" autoPlay loop muted>
              <source src={contactVideo} type="video/mp4" />
            </video>
            <div class="BannerText container">
              <h1 style={{ color: "black" }}>Contact Us</h1>
            </div>
          </section>
        </section>
      </div>
      {/* contact form */}
      <div class="contactForm container">
        <div class="row ">
          <div class="col-md-12 col-xl-6 col-sm-12 col-lg-6 formleft">
            <h2>🌎 Address:</h2>
            <br></br>
            <p>
              CLOVER SECURITY PVT LTD
              <br></br>
              Office No. 316, 3rd Floor<br></br>
              Finswell Near Bajaj Finserv,<br></br>
              Sakore Nagar,<br></br>
              Vimannagar Pune - Maharashtra 411014
            </p>
            <hr></hr>
            <br></br> <br></br>
            <div class="row" style={{margin:'0px'}}>
              <div class="col-md-6 col-sd-12 col-lg-6">
                <h2>☎ Phone :</h2>
                <br></br>
                <p>89757 52441</p>
              </div>
              <div class="col-md-6 col-sd-12 col-lg-6">
                <h2>📩 Email:</h2>
                <br></br>
                <p>9877@religare.in</p>
                <p>raghavoption9877@gmail.com</p>
              </div>
            </div>
            <hr></hr> <br></br> <br></br>
            <h2>⏱ Office Time:</h2>
            <br></br>
            <p>
              Monday to Friday <br></br>9:00 AM TO 5:00 PM
            </p>
            <hr></hr>
          </div>
          <div class="col-md-1"></div>
          <div
            class="col-md-12 col-xl-5 col-sm-12 col-lg-5 justify-content-center contactFormRight"
            style={{
              border: " 1px solid #ededed",
              borderRadius: "20px",
              padding: "40px 8%",
              boxShadow: "0px 0px 8px #3F62BE",
            }}
          >
            <Contact></Contact>
          </div>
        </div>
      </div>
      <center class="container" style={{ paddingBottom: "5rem" }}>
        <h1
          style={{
            fontWeight: "bold",
            paddingBottom: "5rem",
            fontSize: "34px",
            color: "#19024B"
          }}
        >
          Location
        </h1>

        <div class="loactionStyle">
          {/* Google Map Copied Code */}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.2682863132723!2d73.91292159999999!3d18.5619396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c15a81afc021%3A0x1381bafdd2f10fca!2sFinswell!5e0!3m2!1sen!2sin!4v1681287454200!5m2!1sen!2sin"
            width="100%"
            height="450"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </center>
    </div>
  );
}

export default RaghavOptionsContactUs;
