import React from "react";
// import { useState } from "react";
import "./RaghavOptionOurInfo.css";
// import SomeArticles from "./SomeArticles";
import ourinfo from "./ourinfo.png";
// import raghavGallery from "./raghavGallery.js";
import { Link } from "react-router-dom";
// import data from "./data/images.json"
// import Modal from "./Modal.js";
import ourinfoVideo from "./our info.mp4"
import {useEffect} from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";


import image1 from './mission.png'



function RaghavOptionOurInfo() {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);


  const images = [
    {
      original: 'https://raghavoptions.com/static/media/19.png',
      thumbnail: 'https://raghavoptions.com/static/media/19.png',
    },
    {
      original: 'https://raghavoptions.com/static/media/14.png',
      thumbnail: 'https://raghavoptions.com/static/media/14.png',
    },
    {
      original: 'https://raghavoptions.com/static/media/15.png',
      thumbnail: 'https://raghavoptions.com/static/media/15.png',
    },
    {
      original: 'https://raghavoptions.com/static/media/16.png',
      thumbnail: 'https://raghavoptions.com/static/media/16.png',
    },
    {
      original: 'https://raghavoptions.com/static/media/17.png',
      thumbnail: 'https://raghavoptions.com/static/media/17.png',
    },
    {
      original: 'https://raghavoptions.com/static/media/18.png',
      thumbnail: 'https://raghavoptions.com/static/media/18.png',
    },
    {
      original: 'https://raghavoptions.com/static/media/13.png',
      thumbnail: 'https://raghavoptions.com/static/media/13.png',
    },
    {
      original: 'https://raghavoptions.com/static/media/20.png',
      thumbnail: 'https://raghavoptions.com/static/media/20.png',
    },
    {
      original: 'https://raghavoptions.com/static/media/21.png',
      thumbnail: 'https://raghavoptions.com/static/media/21.png',
    },
    {
      original: 'https://raghavoptions.com/static/media/22.png',
      thumbnail: 'https://raghavoptions.com/static/media/22.png',
    },
    {
      original: 'https://raghavoptions.com/static/media/23.png',
      thumbnail: 'https://raghavoptions.com/static/media/23.png',
    },
    {
      original: 'https://raghavoptions.com/static/media/24.png',
      thumbnail: 'https://raghavoptions.com/static/media/24.png',
    },

  ];

  
  return (
    <div>
      <div class="ourInfoBanner font">
        <section class="serviceBannerImg banner-video">
          <section class="video-container">
            <video className="videoTag" autoPlay loop muted>
             <source src={ourinfoVideo} type="video/mp4" /> 
            </video>
            <div class="BannerText container">
              <h1 style={{color:'black'}}>Our Info</h1>
            </div>
          </section>
        </section>
      </div>

      <div>
      <h1 class="mainTitle" style={{padding:'5rem 0'}}>Gallery</h1>
      <ImageGallery 
                    items={images} 
                    showPlayButton={false} 
      />

      </div>

      <div class="ourInfoBlog container">
        <h1 class="mainTitle">Blog</h1>
        <div class="blogName" style={{padding:'0 5rem '}}>
          <div class="card">
            <img src={ourinfo} alt="ourinfo" />
            <div class="card-body">
              <Link to="/SomeArticles"  onClick={() => {
          window.scrollTo({top: 0, left: 0, behavior: 'auto'});
        }}>
              Some Articles
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RaghavOptionOurInfo;
